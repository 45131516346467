import React, {useCallback} from "react";
import {Accordion, LocationSearchFilter} from "mapx-components";
import {
	filterPositionOptionsSelector,
	flattenCountriesSelector,
} from "store/mapx/filter/filterSelectors";
import LocationFilterHelper from "mapx-components/Filters/LocationSearchFilter/LocationFilterHelper";
import {useAppDispatch, useAppSelector} from "hooks";
import {TabSelect} from "components";
import {TCountry, TRegion} from "mapx-components/Filters/LocationSearchFilter/types";
import {TLocationPosition} from "./types";
import {
	mRAllCountriesCountSelector,
	mRCandidateCountryIdsByPositionSelector,
	mRCandidateRegionIdsByPositionSelector,
	mRLocationFilterConfigSelector,
	mRSelectedAnyCountriesSelector,
	mRSelectedCurrentCountriesSelector,
	mRSelectedPreviousCountriesSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {
	setMarketReportMultipleFilterValueInForm,
	setMRLocationsFilterConfig,
} from "store/mapx/market-report/marketReportActions";
import {
	moveLocationsForMarketReportFormFilter,
	setLocationForMarketReportFormFilter,
} from "store/mapx/market-report/marketReportFormAsyncActions";

function LocationFilter() {
	const dispatch = useAppDispatch();

	const filterPositionOptions = useAppSelector(filterPositionOptionsSelector);

	const locationFilterConfig = useAppSelector(mRLocationFilterConfigSelector);

	const position = locationFilterConfig.active_position;

	const flattenCountries = useAppSelector(flattenCountriesSelector);

	const selectedCountriesByPosition = useAppSelector((state) =>
		mRCandidateCountryIdsByPositionSelector(state, position),
	);

	const selectedCountryRegions = useAppSelector((state) =>
		mRCandidateRegionIdsByPositionSelector(state, position),
	);

	const count = useAppSelector(mRAllCountriesCountSelector);

	const currentCountries = useAppSelector(mRSelectedCurrentCountriesSelector);
	const previousCountries = useAppSelector(mRSelectedPreviousCountriesSelector);
	const anyCountries = useAppSelector(mRSelectedAnyCountriesSelector);

	const handlePositionChange = useCallback(
		(updatedPositionValue: string) => {
			dispatch(
				setMRLocationsFilterConfig({
					...locationFilterConfig,
					active_position: updatedPositionValue,
				}),
			);
		},
		[dispatch, locationFilterConfig],
	);

	const {handleCheckChangeForSingleItem, handleCheckChangeForBulkItems, handleRegionCheckChange} =
		LocationFilterHelper({
			selectedCountries: selectedCountriesByPosition,
			selectedCountryRegions,
			flattenCountries,
		});

	const handleChange = useCallback(
		(id: number) => {
			const {nextCountryIds, toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

			dispatch(
				setLocationForMarketReportFormFilter({position, nextCountryIds, toBeSetRegionIds}),
			);
		},
		[dispatch, position, handleCheckChangeForSingleItem],
	);

	const handleTagClick = useCallback(
		(
			id: number,
			fromPosition: TLocationPosition,
			selectedCountryIds: number[],
			region_id?: number,
		) => {
			if (selectedCountryIds) {
				let nextCountryIds = [...selectedCountryIds].filter((i) => i !== id);
				const {toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

				let nextRegionIds = toBeSetRegionIds;

				if (region_id) {
					nextRegionIds = [...selectedCountryRegions].filter((i) => i !== region_id);
				}

				nextCountryIds = nextCountryIds.filter((countryId) => {
					const country = flattenCountries.find((c) => c.id === countryId);

					if (country) {
						const countryRegionIds = country.regions.map(
							(region: TRegion) => region.id,
						);
						const allRegionsIncluded = countryRegionIds.every((regionId: number) =>
							nextRegionIds.includes(regionId),
						);

						if (allRegionsIncluded) {
							nextRegionIds = nextRegionIds.filter(
								(regionId) => !countryRegionIds.includes(regionId),
							);

							return true;
						}
					}

					return !country?.regions.some((region: TRegion) =>
						nextRegionIds.includes(region.id),
					);
				});

				dispatch(
					setLocationForMarketReportFormFilter({
						position: fromPosition,
						nextCountryIds,
						toBeSetRegionIds: nextRegionIds,
					}),
				);
			}
		},
		[dispatch, handleCheckChangeForSingleItem, flattenCountries, selectedCountryRegions],
	);

	const handleTagDrop = useCallback(
		(country_id: number, region_id: string, from: TLocationPosition, to: TLocationPosition) => {
			dispatch(
				moveLocationsForMarketReportFormFilter({
					from: from,
					to: to,
					country_id: country_id,
					region_id: region_id,
				}),
			);
		},
		[dispatch],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMarketReportMultipleFilterValueInForm({
				countries_or: [],
				previous_countries_or: [],
				current_countries_or: [],
				regions_or: [],
				previous_regions_or: [],
				current_regions_or: [],
			}),
		);
	}, [dispatch]);

	const handleBulkUpdate = useCallback(
		(nextCountryIds: number[]) => {
			const toBeSetRegionIds = handleCheckChangeForBulkItems(nextCountryIds);

			dispatch(
				setLocationForMarketReportFormFilter({position, nextCountryIds, toBeSetRegionIds}),
			);
		},
		[dispatch, handleCheckChangeForBulkItems, position],
	);

	const handleRegionChange = useCallback(
		(regionId: number, country: TCountry) => {
			const {toBeSetRegionIds, nextCountryIds} = handleRegionCheckChange(regionId, country);

			dispatch(
				setLocationForMarketReportFormFilter({position, nextCountryIds, toBeSetRegionIds}),
			);
		},
		[dispatch, handleRegionCheckChange, position],
	);

	return (
		<Accordion
			title="Location"
			subtitle={selectedCountriesByPosition?.length + (selectedCountryRegions?.length || 0)}
		>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={filterPositionOptions}
				/>
			</TabSelect.LabelContainer>

			<LocationSearchFilter
				filterType={"candidates"}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				selectedLocation={selectedCountriesByPosition}
				selectedCountryRegions={selectedCountryRegions}
				handleBulkUpdate={handleBulkUpdate}
				handleRegionChange={handleRegionChange}
				handleTagClick={handleTagClick}
				handleMoveLocationTag={handleTagDrop}
				selectedCountries={{
					current: currentCountries,
					previous: previousCountries,
					any: anyCountries,
				}}
				allSelectedCountriesCount={count}
			/>
		</Accordion>
	);
}

export default LocationFilter;
