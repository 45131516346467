import {TMarketReportReducerInitialState} from "store/mapx/market-report/types";
import type {AnyAction} from "redux";
import {
	CREATE_MARKET_MAP_FAIL,
	CREATE_MARKET_REPORT_DONE,
	CREATE_MARKET_REPORT_INIT,
	DISPLAY_MARKET_REPORT_MODAL,
	GET_MARKET_REPORTS_DONE,
	GET_MARKET_REPORTS_FAIL,
	GET_MARKET_REPORTS_INIT,
	RESET_MARKET_REPORT_FORM,
	RESET_MARKET_REPORT_FORM_DATA,
	SET_MARKET_REPORT_FILTER_VALUE_IN_FORM,
	SET_MARKET_REPORT_FORM_DATA,
	SET_MARKET_REPORT_MULTIPLE_FILTER_VALUE_IN_FORM,
	SET_MARKET_REPORT_NAME_IN_FORM,
	SET_MARKET_REPORTS_PAGINATION_INFO,
	SET_MR_COMPANIES_MODE,
	SET_MR_COMPANY_FILTER_CONFIG,
	SET_MR_COMPANY_TYPE_FILTER_CONFIG,
	SET_MR_HEADCOUNT_FILTER_CONFIG,
	SET_MR_INDUSTRIES_FILTER_CONFIG,
	SET_MR_JOB_FUNCTION_FILTER_CONFIG,
	SET_MR_JOB_TITLE_FILTER_MODE,
	SET_MR_JOB_TITLE_MODE,
	SET_MR_KEYWORD_FILTER_CONFIG,
	SET_MR_LOCATIONS_FILTER_CONFIG,
	SET_MR_REVENUE_FILTER_CONFIG,
	UPDATE_MARKET_REPORT_DONE,
	UPDATE_MARKET_REPORT_FAIL,
	UPDATE_MARKET_REPORT_INIT,
} from "store/mapx/mapXActionTypes";
import {IMarketReport} from "api/marketReportApi/types";

export const marketReportReducerInitialState: TMarketReportReducerInitialState = {
	loadingMarketReport: false,
	creatingMarketReport: false,
	updatingMarketReport: false,
	deletingMarketReport: false,
	duplicatingMarketReport: false,
	displayMarketReportModal: false,
	results: [],
	pagination: null,
	createdBy: [],
	companyFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
	},
	candidateCompanyHeadcountFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
	},
	candidateCompanyRevenueFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
	},
	candidateCompanyTypeFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
	},
	candidateIndustriesFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
	},
	candidateLocationFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
	},
	candidateKeywordFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
		active_current_position_connect_logic: "or", // "and" | "or" | "not"
		active_previous_position_connect_logic: "or", // "and" | "or" | "not"
		active_any_position_connect_logic: "or", // "and" | "or" | "not"
	},
	candidateJobFunctionFilterConfig: {
		active_position: "current", // "current" | "previous" | "any"
		active_current_position_connect_logic: "or", // "and" | "or" | "not"
		active_previous_position_connect_logic: "or", // "and" | "or" | "not"
		active_any_position_connect_logic: "or", // "and" | "or" | "not"
	},
	jobTitleFilterMode: "contains", // contains | close
	marketReportForm: {
		name: "",
		filters: {
			companies: [],
			current_companies: [],
			previous_companies: [],
			current_seniority: [],

			current_job_titles_or: [],

			specialisms_and: [],
			specialisms_or: [],
			specialisms_not: [],

			current_specialisms_and: [],
			current_specialisms_or: [],
			current_specialisms_not: [],

			previous_specialisms_and: [],
			previous_specialisms_or: [],
			previous_specialisms_not: [],

			text_keywords_or: [],
			text_keywords_and: [],
			text_keywords_not: [],

			current_text_keywords_or: [],
			current_text_keywords_and: [],
			current_text_keywords_not: [],

			previous_text_keywords_or: [],
			previous_text_keywords_and: [],
			previous_text_keywords_not: [],

			headquarters_countries: [],
			countries_or: [],
			previous_countries_or: [],
			current_countries_or: [],

			regions_or: [],
			previous_regions_or: [],
			current_regions_or: [],

			industries: [],
			previous_industries: [],
			current_industries: [],

			revenue: [],
			current_revenue: [],
			previous_revenue: [],

			company_size: [],
			current_company_size: [],
			previous_company_size: [],

			company_type: [],
			previous_company_type: [],
			current_company_type: [],

			company_events: {
				types: [],
				size: [],
				dates: [],
			},
		},
	},
	selectedCompanies: [],
};

const marketReportReducer = (state = marketReportReducerInitialState, action: AnyAction) => {
	switch (action.type) {
		// case DELETE_MARKET_MAP_INIT: {
		//     const id = action.payload;
		//
		//     return {
		//         ...state,
		//         deletion_in_progress: [...state.deletion_in_progress, id],
		//     };
		// }
		// case DELETE_MARKET_MAP_DONE: {
		//     const id = action.payload;
		//
		//     return {
		//         ...state,
		//         deletion_in_progress: state.deletion_in_progress.filter(
		//             (item: number) => item !== id,
		//         ),
		//         // @TODO: Define server types for TRootState
		//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
		//         results: state.results.filter((item: any) => item.id !== id),
		//         // @TODO: Define server types for TRootState
		//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
		//         modal_results: state.modal_results.filter((item: any) => item.id !== id),
		//     };
		// }
		// case DELETE_MARKET_MAP_FAIL: {
		//     const {id} = action.payload;
		//
		//     return {
		//         ...state,
		//         deletion_in_progress: state.deletion_in_progress.filter(
		//             (item: number) => item !== id,
		//         ),
		//     };
		// }
		// case UPDATE_MARKET_MAP_INIT:
		//     return {
		//         ...state,
		//         creation_in_progress: true,
		//         creation_error: null,
		//     };
		// case UPDATE_MARKET_MAP_DONE: {
		//     const marketMap = action.payload;
		//
		//     return {
		//         ...state,
		//         creation_in_progress: false,
		//         // @TODO: Define server types for TRootState
		//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
		//         results: state.results.map((item: any) =>
		//             item.id === marketMap.id ? marketMap : item,
		//         ),
		//         // @TODO: Define server types for TRootState
		//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
		//         modal_results: state.modal_results.map((item: any) =>
		//             item.id === marketMap.id ? marketMap : item,
		//         ),
		//         edit_market_map_modal: false,
		//     };
		// }
		// case UPDATE_MARKET_MAP_FAIL:
		//     return {
		//         ...state,
		//         creation_in_progress: false,
		//         creation_error: action.payload,
		//     };

		case RESET_MARKET_REPORT_FORM:
			return {
				...state,
				marketReportForm: {
					...marketReportReducerInitialState.marketReportForm,
				},
			};

		case DISPLAY_MARKET_REPORT_MODAL:
			return {
				...state,
				displayMarketReportModal: action.payload,
			};

		case SET_MARKET_REPORT_NAME_IN_FORM:
			return {
				...state,
				marketReportForm: {
					...state.marketReportForm,
					name: action.payload,
				},
			};

		case SET_MARKET_REPORT_FILTER_VALUE_IN_FORM:
			return {
				...state,
				marketReportForm: {
					...state.marketReportForm,
					filters: {
						...state.marketReportForm.filters,
						[action.payload.key]: action.payload.value,
					},
				},
			};
		case SET_MARKET_REPORT_MULTIPLE_FILTER_VALUE_IN_FORM:
			return {
				...state,
				marketReportForm: {
					...state.marketReportForm,
					filters: {
						...state.marketReportForm.filters,
						...action.payload,
					},
				},
			};
		case SET_MARKET_REPORT_FORM_DATA:
			return {
				...state,
				marketReportForm: {
					...state.marketReportForm,
					...action.payload,
					filters: {
						...state.marketReportForm.filters,
						...action.payload.filters,
					},
				},
			};
		case RESET_MARKET_REPORT_FORM_DATA:
			return {
				...state,
				marketReportForm: {
					...marketReportReducerInitialState.marketReportForm,
				},
			};
		case SET_MR_COMPANY_FILTER_CONFIG:
			return {
				...state,
				companyFilterConfig: {
					...state.companyFilterConfig,
					...action.payload,
				},
			};
		case SET_MR_JOB_FUNCTION_FILTER_CONFIG:
			return {
				...state,
				candidateJobFunctionFilterConfig: {
					...state.candidateJobFunctionFilterConfig,
					...action.payload,
				},
			};

		case SET_MR_INDUSTRIES_FILTER_CONFIG:
			return {
				...state,
				candidateIndustriesFilterConfig: {
					...state.candidateIndustriesFilterConfig,
					...action.payload,
				},
			};

		case SET_MR_LOCATIONS_FILTER_CONFIG:
			return {
				...state,
				candidateLocationFilterConfig: {
					...state.candidateLocationFilterConfig,
					...action.payload,
				},
			};

		case SET_MR_KEYWORD_FILTER_CONFIG:
			return {
				...state,
				candidateKeywordFilterConfig: {
					...state.candidateKeywordFilterConfig,
					...action.payload,
				},
			};

		case SET_MR_JOB_TITLE_FILTER_MODE:
			return {
				...state,
				job_title_filter_mode: action.payload,
			};

		case SET_MR_HEADCOUNT_FILTER_CONFIG:
			return {
				...state,
				candidateCompanyHeadcountFilterConfig: {
					...state.candidateCompanyHeadcountFilterConfig,
					...action.payload,
				},
			};

		case SET_MR_REVENUE_FILTER_CONFIG:
			return {
				...state,
				candidateCompanyRevenueFilterConfig: {
					...state.candidateCompanyRevenueFilterConfig,
					...action.payload,
				},
			};

		case SET_MR_COMPANY_TYPE_FILTER_CONFIG:
			return {
				...state,
				candidateCompanyTypeFilterConfig: {
					...state.candidateCompanyTypeFilterConfig,
					...action.payload,
				},
			};
		case SET_MR_JOB_TITLE_MODE:
			return {
				...state,
				jobTitleFilterMode: action.payload,
			};
		case SET_MR_COMPANIES_MODE:
			return {
				...state,
				selectedCompanies: action.payload,
			};

		case CREATE_MARKET_REPORT_INIT:
			return {
				...state,
				creatingMarketReport: true,
			};
		case CREATE_MARKET_REPORT_DONE: {
			const marketReport = action.payload;

			return {
				...state,
				creatingMarketReport: false,
				displayMarketReportModal: false,
				results: [marketReport, ...state.results],
				marketReportForm: {...state.marketReportForm, id: marketReport.id},
			};
		}
		case CREATE_MARKET_MAP_FAIL:
			return {
				...state,
				creatingMarketReport: false,
			};
		case UPDATE_MARKET_REPORT_INIT:
			return {
				...state,
				updatingMarketReport: true,
			};
		case UPDATE_MARKET_REPORT_DONE:
			const marketReport = action.payload;

			return {
				...state,
				updatingMarketReport: false,
				displayMarketReportModal: false,
				results: [
					marketReport,
					...state.results.filter((item: IMarketReport) => item.id !== marketReport.id),
				],
				marketReportForm: {...state.marketReportForm, id: marketReport.id},
			};
		case UPDATE_MARKET_REPORT_FAIL: {
			return {
				...state,
				updatingMarketReport: false,
			};
		}

		case GET_MARKET_REPORTS_INIT:
			return {
				...state,
				loadingMarketReport: true,
			};
		case GET_MARKET_REPORTS_DONE:
			const {
				data: {pagination, results},
				name,
				createdBy,
			} = action.payload;

			return {
				...state,
				name,
				created_by: createdBy,
				results: results,
				pagination: pagination || {},
				loadingMarketReport: false,
			};
		case GET_MARKET_REPORTS_FAIL:
			return {
				...state,
				loadingMarketReport: false,
			};
		case SET_MARKET_REPORTS_PAGINATION_INFO:
			return {
				...state,
				pagination: action.payload,
			};
		default:
			return state;
	}
};

export default marketReportReducer;
