import React from "react";
import styles from "./Diversity.module.scss";
import GenderDiversity from "./GenderDiversity";
import DiversityPathways from "./DiversityPathways";

const MarketReportDiversity = () => {
	return (
		<div className={styles.container}>
			<h2>Overall Diversity 32%</h2>
			<hr />
			<div className={styles.chartBox}>
				<GenderDiversity />
				<DiversityPathways />
			</div>
		</div>
	);
};

export default MarketReportDiversity;
