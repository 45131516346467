import styles from "../Diversity.module.scss";

const GenderDiversity = () => {
	return (
		<div className={styles.genderDiversity}>
			<div className={styles.titleSection}>
				<p>Gender Diversity</p>
			</div>
			<hr />
			{/*@TODO Add the gender diversity chart or related content here */}
		</div>
	);
};

export default GenderDiversity;
