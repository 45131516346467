import React, {useCallback, useEffect, useMemo, useState} from "react";

import styles from "./styles.module.scss";
import {useSelector} from "react-redux";
import {companyFiltersSelector} from "store/mapx/filter/filterSelectors";
import {ActionAltButton, CompaniesSkeletonLoader, CompanyCard} from "mapx-components";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	buildCompanyFilterApiPayload,
	buildQueryParameter,
	removeDuplicateObjectFromArrayByKeepingTheFirstIndex,
} from "helpers/filterHandlers";
import {resetCompaniesFilters} from "store/mapx/filter/filterActions";
import classNames from "classnames";
import {ClearSelectionIcon, SavePlusIcon} from "assets/icons";
import {saveCompaniesByFilters} from "store/mapx/target-list/targetListAsyncActions";
import CompanyFilters from "containers/Filters/CompanyFilters";
import {
	companiesInProgressSelector,
	companiesPaginationInfoSelector,
	companiesSelector,
	filteredCompaniesInProgressSelector,
} from "store/mapx/company/companySelectors";
import {STCompany, TFilterCompanyPayload} from "api/companyApi/types";
import {getCompaniesData, getFilteredCompaniesData} from "store/mapx/company/companyAsyncActions";
import {cancelRequest, getCancelToken} from "api/cancelTokens";
import {GET_COMPANIES_SEARCH_RESULTS_TOKEN} from "api/requestCancelTokenStrings";
import {ATPagination} from "api/types";
import css from "../../Projects/projects.module.scss";
import Pagination from "mapx-components/Pagination";
import {setCompaniesPaginationInfo} from "store/mapx/company/companyActions";

const CompanyFilterSearch = ({companiesResultCount}: {companiesResultCount: number}) => {
	const dispatch = useAppDispatch();

	const [savingAll, setSavingAll] = useState<boolean>(false);

	const [requestCancelled, setRequestCancelled] = useState(false);

	const [loadingMoreResults, setLoadingMoreResults] = useState(false);

	const companiesInProgress = useSelector(companiesInProgressSelector);

	const filteredCompaniesInProgress = useSelector(filteredCompaniesInProgressSelector);

	const filterSelection = useSelector(companyFiltersSelector);

	const companies: STCompany[] = useSelector(companiesSelector);

	const pagination: ATPagination = useSelector(companiesPaginationInfoSelector);

	const companiesApiPayload = useMemo(() => {
		if (filterSelection) {
			return buildCompanyFilterApiPayload(filterSelection);
		} else {
			return null;
		}
	}, [filterSelection]);

	const companiesQueryString = useMemo(() => {
		if (filterSelection) {
			return buildQueryParameter(filterSelection);
		} else {
			return null;
		}
	}, [filterSelection]);

	const fetchResults = useCallback(
		async (page: number, perPage: number, query: TFilterCompanyPayload | null) => {
			const config = {cancelToken: getCancelToken(GET_COMPANIES_SEARCH_RESULTS_TOKEN).token};

			let response;
			const pagination = {page: page, per_page: perPage};

			if (query) {
				response = await dispatch(getFilteredCompaniesData(query, pagination, config));
			} else {
				response = await dispatch(getCompaniesData(pagination, config));
			}

			setLoadingMoreResults(false);

			if (response?.cancelled) {
				setRequestCancelled(true);
			} else {
				setRequestCancelled(false);
			}
		},
		[dispatch],
	);

	useEffect(() => {
		if (companiesApiPayload !== null) {
			fetchResults(pagination?.page, pagination?.per_page, companiesApiPayload).then(
				(r) => r,
			);
		} else {
			fetchResults(pagination?.page, pagination?.per_page, null).then((r) => r);
		}

		return () => {
			cancelRequest(GET_COMPANIES_SEARCH_RESULTS_TOKEN);
		};
	}, [companiesApiPayload, fetchResults, pagination?.page, pagination?.per_page, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(setCompaniesPaginationInfo({page: 1, per_page: 20}));
		};
	}, [dispatch, companiesApiPayload]);

	const onPageChange = async (currentPageNumber: number) => {
		dispatch(setCompaniesPaginationInfo({...pagination, page: currentPageNumber}));

		setLoadingMoreResults(true);
	};

	const onPerPageChange = async (count: number) => {
		dispatch(setCompaniesPaginationInfo({...pagination, per_page: count}));
	};

	const companiesFetchInProgress = useMemo(
		() => companiesInProgress || filteredCompaniesInProgress || requestCancelled,
		[companiesInProgress, filteredCompaniesInProgress, requestCancelled],
	);

	const resetFilters = useCallback(() => {
		dispatch(resetCompaniesFilters());
	}, [dispatch]);

	const handleAddAllCandidatesToProject = useCallback(async () => {
		const hasFilter = !!companiesQueryString;

		if (!hasFilter) {
			window.alert("Please apply filters.");
		} else if (hasFilter && companies?.length > 5000) {
			window.alert(
				"Companies search result count exceeds limit. Please consider narrowing down your search.",
			);
		} else {
			setSavingAll(true);
			await dispatch(saveCompaniesByFilters(companiesQueryString, false));
			setSavingAll(false);
		}
	}, [companies?.length, companiesQueryString, dispatch]);

	const isAddAllButtonVisibleForCompanies = useMemo(() => {
		return (
			pagination?.count > 0 && pagination?.count < 5001
			// !displayedOnlySimilarProfiles
		);
	}, [pagination?.count]);

	const selectedCompanies = useAppSelector((state) => state.filter.selected_companies);

	const companiesAll: STCompany[] = useMemo(() => {
		const allCompanies = companiesFetchInProgress
			? [...selectedCompanies]
			: [...selectedCompanies, ...companies];

		return removeDuplicateObjectFromArrayByKeepingTheFirstIndex(allCompanies, "id");
	}, [selectedCompanies, companies, companiesFetchInProgress]);

	const totalPagesCount = useMemo(
		() =>
			pagination?.count > 10000
				? Math.ceil(10000 / pagination?.per_page)
				: Math.ceil(pagination?.count / pagination?.per_page),
		[pagination?.count, pagination?.per_page],
	);

	return (
		<div className={styles.container}>
			<CompanyFilters />
			<br />
			<br />
			<br />

			<div className={styles.resultsWrapper}>
				<div className={styles.headerSectionWrapper}>
					<div className={styles.actionButtonsRight}>
						{!!companiesQueryString && (
							<ActionAltButton
								key={"clear_filter"}
								customClass={classNames(styles.resetFilterButton)}
								iconComponent={<ClearSelectionIcon />}
								handleClick={resetFilters}
								title={"Clear Filters"}
							/>
						)}

						<div className={styles.count}>Results: {companiesResultCount}</div>
					</div>

					<div className={styles.actionButtonsLeft}>
						{isAddAllButtonVisibleForCompanies && (
							<ActionAltButton
								key={"add_all_to_projects"}
								customClass={classNames(styles.toggleExpandButton)}
								loading={savingAll}
								disabled={savingAll}
								iconComponent={<SavePlusIcon />}
								handleClick={handleAddAllCandidatesToProject}
								title={
									savingAll ? "Adding all to project..." : "Add all to project"
								}
							/>
						)}
					</div>
				</div>

				{(companiesFetchInProgress || loadingMoreResults) && (
					<CompaniesSkeletonLoader count={5} />
				)}

				<LazyLoadComponent>
					{!companiesFetchInProgress &&
						!loadingMoreResults &&
						companiesAll?.map((company: STCompany, index: number) => {
							return (
								<CompanyCard
									key={company.id}
									company={company}
									isRecommended={company.recommended}
									position={index + 1}
									shouldTrack={!!companiesQueryString}
								/>
							);
						})}
				</LazyLoadComponent>

				{!companiesFetchInProgress &&
					!loadingMoreResults &&
					pagination &&
					pagination?.count >= 10 && (
						<div className={css.paginationWrapper}>
							<Pagination
								currentPage={pagination?.page}
								resultsCount={pagination?.count}
								totalPages={totalPagesCount}
								perPage={pagination?.per_page}
								onPageChange={onPageChange}
								onPerPageChange={onPerPageChange}
							/>
						</div>
					)}

				{!companies?.length && companiesResultCount === 0 && (
					<div className={styles.noResult}>
						<div>
							Hmm, I have not found any results &#128542;. Try to re-configure your
							search criteria.
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CompanyFilterSearch;
