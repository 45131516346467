import React, {useMemo, useState} from "react";
import styles from "../Diversity.module.scss";
import classNames from "classnames";
import {TextTabPane} from "mapx-components";
import MostDiversePathways from "../Charts/MostDiversePathways";

const chartStyle = [
	{id: 0, title: "By Function"},
	{id: 1, title: "By Industry"},
	{id: 2, title: "By Location"},
];

const dummyDataByFunction = {
	labels: [
		"Consultant",
		"Commercial",
		"Design",
		"Education",
		"Finance",
		"General Management",
		"Other",
	],
	datasets: [
		{
			label: "",
			data: [70, 48, 34, 23, 18, 15, 5, 100],
			backgroundColor: "rgba(124, 163, 178, 1)",
			borderRadius: 5,
		},
	],
};

const DiversityPathways = () => {
	const [tabIndex, setTabIndex] = useState(0);

	const tabs = useMemo(() => {
		const FunctionTab = (
			<TextTabPane title="By Function" key="function">
				<span className={styles.tabContainer}>
					<MostDiversePathways data={dummyDataByFunction} />
				</span>
			</TextTabPane>
		);
		const industryTab = (
			<TextTabPane title="By Industry" key="industry">
				<span className={styles.tabContainer}>
					<MostDiversePathways data={dummyDataByFunction} />
				</span>
			</TextTabPane>
		);
		const locationTab = (
			<TextTabPane title="By Location" key="location">
				<span className={styles.tabContainer}>
					<MostDiversePathways data={dummyDataByFunction} />
				</span>
			</TextTabPane>
		);

		return [FunctionTab, industryTab, locationTab];
	}, []);

	const renderTabOptions = chartStyle.map(({id, title}) => (
		<div
			key={id}
			onClick={() => setTabIndex(id)}
			className={classNames(styles.tabTitle, {
				[styles.activeTab]: tabIndex === id,
			})}
		>
			{title}
		</div>
	));

	return (
		<div className={styles.diversePathways}>
			<div className={styles.titleSection}>
				<p>Most Diverse Pathways</p>
				<div className={styles.tabContainer}>
					<div className={classNames(styles.tabs)}>{renderTabOptions}</div>
				</div>
			</div>
			<hr />
			{tabs[tabIndex]}
		</div>
	);
};

export default DiversityPathways;
