import {AxiosRequestConfig, CanceledError} from "axios";
import mapXCompanyApi from "api/companyApi";
import {successResponse} from "helpers/map";
import {getCancelToken} from "api/cancelTokens";
import {SEARCH_COMPANY_BY_DESCRIPTION} from "api/requestCancelTokenStrings";

export const getSearchedCompaniesDataByDescription = (description: string) => async () => {
	const config: AxiosRequestConfig = {
		cancelToken: getCancelToken(SEARCH_COMPANY_BY_DESCRIPTION).token,
	};

	try {
		if (description !== null) {
			const response = await mapXCompanyApi.getCompanyLLMSuggestions(description, config);

			if (response == null) {
				throw new CanceledError("Operation canceled due to new request.");
			} else if (successResponse(response, 200)) {
				return response.data?.results || [];
			}
		}

		return [];
	} catch (error) {
		console.error("error", error);

		return [];
	}
};
