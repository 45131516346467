import styles from "./marketReports.module.scss";
import CriteriaBreakDown from "./CriteriaBreakDown";
import CoreRoleRequirements from "./CoreRoleRequirements";
import MarketReportDiversity from "./Diversity";
import RelevantInfo from "./ReleventInfoSection";
import MarketReportMobility from "./Moblity";
import MarketReportTenure from "./Tenure";
import {MainWrapper} from "mapx-components";
import {Button} from "../../components";
import {PencilIcon} from "../../assets/icons";
import React from "react";
import IconPPTX from "./Icons/PptxIcon";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "hooks";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";

const MarketReportDetails = () => {
	const history = useHistory();

	const marketReport = useAppSelector(getMarketReportFormSelector);

	return (
		<MainWrapper>
			<div className={styles.wrapper}>
				<div className={styles.stickyHeader}>
					<h1>Market for the Technology Officers in US</h1>
					<div className={styles.rightSection}>
						<Button name={"Edit Filters"} type={"button"} className={styles.pptButton}>
							<IconPPTX /> Download PPT
						</Button>
						<Button
							name={"Edit Report"}
							onClick={() => history.push(`/market-reports/edit/${marketReport.id}`)}
							className={styles.editProjectButton}
						>
							<PencilIcon color={"#4E5555"} /> Edit Report
						</Button>
					</div>
				</div>
				<div className={styles.container}>
					<CoreRoleRequirements />
					<CriteriaBreakDown />
					<MarketReportDiversity />
					<RelevantInfo />
					{/*@Todo:Location component*/}
					<MarketReportMobility />
					{/*@Todo:Background Analysis component*/}
					<MarketReportTenure />
				</div>
			</div>
		</MainWrapper>
	);
};

export default MarketReportDetails;
