import React, {useCallback, useEffect, useMemo, useState} from "react";
import MainWrapper from "mapx-components/Layouts/MainWrapper";
import styles from "./styles.module.scss";
import CoreRoleRequirements from "mapx-pages/MarketReportDetails/CoreRoleRequirements";
import {PencilIcon, RoundedPlus} from "assets/icons";
import {Button} from "components";
import FilterSelection from "mapx-pages/MarketReportCreate/FilterSelection";
import {GeneralButton} from "mapx-components";
import {
	IMarketReport,
	TCreateMarketReportForm,
	TMarketReportFilter,
} from "api/marketReportApi/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	creatingMarketReportSelector,
	getMarketReportFormSelector,
	updatingMarketReportSelector,
} from "store/mapx/market-report/marketReportSelector";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {getFilteredCandidatesForSearch} from "store/mapx/candidate/candidateResultsAsyncActions";
import {cancelRequest, getCancelToken} from "api/cancelTokens";
import {GET_CANDIDATES_RESULTS_COUNT_TOKEN} from "api/requestCancelTokenStrings";
import Loader from "components/Plugins/Loader";
import {formatCandidateNumberToHumanReadableFormat} from "mapx-pages/CandidateSearch/utils";
import MarketReportModal from "mapx-components/Modals/MarketReportModal";
import {displayMarketReportModal} from "store/mapx/market-report/marketReportActions";
import {TJobTitleFilterMode} from "store/mapx/market-report/types";
import {mRCandidateJobTitleFilterModeSelector} from "store/mapx/market-report/marketReportFilterSelectors";
import {
	createMarketReport,
	updateMarketReport,
} from "store/mapx/market-report/marketReportAsyncActions";
import {useHistory} from "react-router-dom";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";

const MarketReportCreate = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const [estimatedCount, setEstimatedCount] = useState(0);

	const [loading, setLoading] = useState(false);

	const marketReportForm = useAppSelector(getMarketReportFormSelector);

	const creatingReport = useAppSelector(creatingMarketReportSelector);

	const updatingReport = useAppSelector(updatingMarketReportSelector);

	const jobTitleFilterMode: TJobTitleFilterMode = useAppSelector(
		mRCandidateJobTitleFilterModeSelector,
	);

	const hasFilterSelection = useMemo(() => {
		return Object.values(marketReportForm?.filters).some((filterValue) => {
			if (Array.isArray(filterValue)) {
				return filterValue.length > 0; // Check if the array has items
			} else if (typeof filterValue === "object" && filterValue !== null) {
				// Handle nested objects like `company_events`
				return Object.values(filterValue).some((nestedValue) => {
					if (Array.isArray(nestedValue)) {
						return nestedValue.length > 0; // Check if the nested array has items
					}

					return false;
				});
			}

			return false;
		});
	}, [marketReportForm?.filters]);

	const isActionButtonEnabled = useMemo(() => {
		if (hasFilterSelection) {
			return (
				(jobTitleFilterMode === "contains" &&
					marketReportForm?.filters?.current_job_titles_or?.length > 0) ||
				(marketReportForm?.filters?.current_specialisms_or?.length > 0 &&
					marketReportForm?.filters?.current_seniority?.length > 0)
			);
		}

		return false;
	}, [
		hasFilterSelection,
		jobTitleFilterMode,
		marketReportForm?.filters?.current_job_titles_or?.length,
		marketReportForm?.filters?.current_seniority?.length,
		marketReportForm?.filters?.current_specialisms_or?.length,
	]);

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...marketReportForm.filters}) as Omit<
			ICandidateSearchApiRequestPayload,
			"pagination"
		>;

		if (payload) {
			if (
				jobTitleFilterMode === "close" &&
				payload.filters &&
				payload.filters?.current_job_titles_or
			) {
				delete payload.filters.current_job_titles_or;
			}

			return payload;
		}

		return null;
	}, [marketReportForm.filters, jobTitleFilterMode]);

	useEffect(() => {
		if (candidateFilterPayload) {
			const apiPayload = {
				...candidateFilterPayload,
				pagination: {
					page: 1,
					per_page: 1,
				},
			};

			setLoading(true);

			dispatch(
				getFilteredCandidatesForSearch(apiPayload, {
					cancelToken: getCancelToken(GET_CANDIDATES_RESULTS_COUNT_TOKEN).token,
				}),
			)
				//eslint-disable-next-line
				.then((response: any) => {
					if (response) {
						setEstimatedCount(response.paginationInfo.count);
					} else {
						setEstimatedCount(0);
					}
				})
				.finally(() => setLoading(false));
		} else {
			cancelRequest(GET_CANDIDATES_RESULTS_COUNT_TOKEN);
		}
	}, [candidateFilterPayload, dispatch]);

	const InlineLoaderComponent = <Loader height={16} width={16} color={"#4E5555"} type={"Oval"} />;

	useEffect(() => {
		if (marketReportForm?.name?.length < 2) {
			dispatch(displayMarketReportModal(true));
		}
	}, [dispatch, marketReportForm?.name]);

	const handleCreateOrUpdateMarketReport = useCallback(async () => {
		if (hasFilterSelection) {
			const payload = buildCandidateApiRequestPayload(marketReportForm.filters);

			if (payload && payload.filters) {
				const formPayload: TCreateMarketReportForm = {
					name: marketReportForm.name,
					filters: payload.filters as TMarketReportFilter,
				};

				let response: Nullable<IMarketReport>;

				if (marketReportForm.id !== undefined) {
					response = await dispatch(updateMarketReport(formPayload, marketReportForm.id));
				} else {
					response = await dispatch(createMarketReport(formPayload));
				}

				if (response) {
					history.push(`/market-reports/${response.id}`);
				}
			}
		}
	}, [hasFilterSelection, marketReportForm, dispatch, history]);

	return (
		<MainWrapper>
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<h1>{marketReportForm.name}</h1>

					<Button
						name={"Edit Report Name"}
						onClick={() => dispatch(displayMarketReportModal(true))}
						className={styles.editButton}
					>
						<PencilIcon color={"#4E5555"} /> Edit Report Name
					</Button>
				</div>

				<CoreRoleRequirements />

				<FilterSelection />

				<div className={styles.footer}>
					<div className={styles.estimatedCount}>
						Estimated Profile Matches:
						{loading ? (
							InlineLoaderComponent
						) : (
							<span className={styles.count}>
								{estimatedCount
									? formatCandidateNumberToHumanReadableFormat(estimatedCount)
									: "-"}
							</span>
						)}
					</div>

					<GeneralButton
						customClass={styles.createButton}
						title={
							marketReportForm?.id !== undefined ? "Update Report" : "Create a Report"
						}
						icon={<RoundedPlus color={"#fff"} />}
						handleClick={handleCreateOrUpdateMarketReport}
						disabled={!isActionButtonEnabled}
						loadingSave={creatingReport || updatingReport}
						onKeyDown={(event) => {
							console.log(event.code);
						}}
						tabIndex={0}
					/>
				</div>
			</div>

			<MarketReportModal />
		</MainWrapper>
	);
};

export default MarketReportCreate;
