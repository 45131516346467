import axios from "axios";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {
	filteredSavedPeopleFetchCompleted,
	filteredSavedPeopleFetchIsInProgress,
	getTargetListCandidatesDone,
	getTargetListCandidatesFail,
	getTargetListCandidatesInit,
} from "store/mapx/target-list/targetListActions";
import mapXCandidateApi from "api/candidateApi";
import {successResponse} from "helpers/map";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {
	additionalProfileFiltersSelector,
	apCandidateOrderingSelector,
} from "../additional-profiles/additionalProfilesSelectors";
import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {
	GET_FILTERED_SAVED_PEOPLE_TOKEN,
	GET_TARGETLIST_CANDIDATES_TOKEN,
} from "api/requestCancelTokenStrings";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";

export const getTargetListCandidates =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const cancelToken = getCancelToken(GET_TARGETLIST_CANDIDATES_TOKEN);

		try {
			const state = getState();

			const targetListID = targetListIDSelector(state);

			const currentSorting = apCandidateOrderingSelector(state);

			dispatch(getTargetListCandidatesInit());

			const apiPayload: ICandidateSearchApiRequestPayload = {
				filters: {
					target_list: targetListID,
				},
				pagination: {
					page: 1,
					per_page: 10000,
				},
				sorting_options: {
					ordering: currentSorting,
				},
			};

			const response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, {
				cancelToken: cancelToken.token,
			});

			if (response) {
				if (successResponse(response, 200)) {
					const data = response.data;

					dispatch(getTargetListCandidatesDone(data));
				} else {
					dispatch(getTargetListCandidatesFail({error: "something went wrong"}));
				}
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				dispatch(getTargetListCandidatesFail({error}));
			}
		} finally {
			clearCancelToken(GET_TARGETLIST_CANDIDATES_TOKEN);
		}
	};

export const getFilteredSavedPeople =
	(payload: Omit<ICandidateSearchApiRequestPayload, "pagination">) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const cancelToken = getCancelToken(GET_FILTERED_SAVED_PEOPLE_TOKEN);

		try {
			dispatch(filteredSavedPeopleFetchIsInProgress(true));

			const apiPayload: ICandidateSearchApiRequestPayload = {
				...payload,
				pagination: {
					page: 1,
					per_page: 10000,
				},
			};

			const response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, {
				cancelToken: cancelToken.token,
			});

			if (successResponse(response, 200)) {
				const data = response.data;

				dispatch(filteredSavedPeopleFetchCompleted(data));
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			}
		} finally {
			clearCancelToken(GET_FILTERED_SAVED_PEOPLE_TOKEN);
			dispatch(filteredSavedPeopleFetchIsInProgress(false));
		}
	};

export const updateProjectSavedPeopleCandidatesInTheList =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		try {
			const state = getState();

			const apFilters = additionalProfileFiltersSelector(state);

			const payload = buildCandidateApiRequestPayload({...apFilters}, true);

			const targetListID = targetListIDSelector(state);

			if (payload !== null && payload.filters && Object.keys(payload.filters).length > 0) {
				const apiPayload = {
					filters: {
						...payload.filters,
						target_list: targetListID,
					},
					sorting_options: {
						...payload.sorting_options,
					},
				};
				dispatch(getFilteredSavedPeople(apiPayload));
			} else {
				dispatch(getTargetListCandidates());
			}
		} catch (error) {
			console.error("error in updateProjectSavedPeopleCandidatesInTargetList:", error);
		}
	};
