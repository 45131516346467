import styles from "./Mobility.module.scss";
import {TMovementProps} from "../types";
import {useEffect, useState} from "react";
import {LazyLoadComponent} from "react-lazy-load-image-component";

const MobilityCard = ({
	title,
	average,
	description,
	level,
	value,
	lowValueLabel,
	mediumValueLabel,
	highValueLabel,
}: TMovementProps) => {
	const [rotation, setRotation] = useState(-90);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setRotation(value);
		}, 500);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<LazyLoadComponent>
			<div className={styles.internationalMovements}>
				<div className={styles.titleSection}>
					<p>{title}</p>
					<span className={styles.average}>
						Average:<span> {average}</span>
					</span>
				</div>
				<hr />
				<p className={styles.description}>{description}</p>
				<div className={styles.gaugeChartContainer}>
					<div className={styles.gaugeChart}>
						<span className={styles.gaugeLabelLeft}>
							{lowValueLabel.split(" ").map((word, index) => (
								<div key={index}>{word}</div>
							))}
						</span>
						<svg
							width="96"
							height="104"
							viewBox="0 0 96 104"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g opacity="0.5">
								<mask id="path-1-inside-1_2630_35284" fill="white">
									<path d="M0 104C0 82.9356 5.54475 62.2423 16.077 44C26.6091 25.7577 41.7577 10.6091 60 0.0769501L96 62.4308C88.7031 66.6437 82.6437 72.7031 78.4308 80C74.2179 87.2969 72 95.5742 72 104H0Z" />
								</mask>
								<path
									d="M0 104C0 82.9356 5.54475 62.2423 16.077 44C26.6091 25.7577 41.7577 10.6091 60 0.0769501L96 62.4308C88.7031 66.6437 82.6437 72.7031 78.4308 80C74.2179 87.2969 72 95.5742 72 104H0Z"
									fill="#927FBB"
									stroke="white"
									strokeWidth="2"
									mask="url(#path-1-inside-1_2630_35284)"
								/>
							</g>
						</svg>
						<span className={styles.gaugeLabelCenter}>{mediumValueLabel}</span>
						<svg
							className={styles.center}
							width="120"
							height="79"
							viewBox="0 0 120 79"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g opacity="0.75">
								<mask id="path-1-inside-1_2630_35285" fill="white">
									<path d="M1.52588e-05 16.0769C18.2423 5.54475 38.9356 2.5119e-07 60 0C81.0644 -2.5119e-07 101.758 5.54475 120 16.0769L84 78.4308C76.7031 74.2179 68.4258 72 60 72C51.5742 72 43.2969 74.2179 36 78.4308L1.52588e-05 16.0769Z" />
								</mask>
								<path
									d="M1.52588e-05 16.0769C18.2423 5.54475 38.9356 2.5119e-07 60 0C81.0644 -2.5119e-07 101.758 5.54475 120 16.0769L84 78.4308C76.7031 74.2179 68.4258 72 60 72C51.5742 72 43.2969 74.2179 36 78.4308L1.52588e-05 16.0769Z"
									fill="#927FBB"
									stroke="white"
									strokeWidth="2"
									mask="url(#path-1-inside-1_2630_35285)"
								/>
							</g>
						</svg>
						<span className={styles.gaugeLabelRight}>
							{highValueLabel.split(" ").map((word, index) => (
								<div key={index}>{word}</div>
							))}
						</span>
						<svg
							className={styles.right}
							width="96"
							height="104"
							viewBox="0 0 96 104"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask id="path-1-inside-1_2630_35286" fill="white">
								<path d="M36 0.0769444C54.2423 10.6091 69.3908 25.7577 79.923 44C90.4552 62.2423 96 82.9356 96 104H24C24 95.5742 21.7821 87.2969 17.5692 80C13.3563 72.7031 7.29692 66.6437 -7.62939e-06 62.4308L36 0.0769444Z" />
							</mask>
							<path
								d="M36 0.0769444C54.2423 10.6091 69.3908 25.7577 79.923 44C90.4552 62.2423 96 82.9356 96 104H24C24 95.5742 21.7821 87.2969 17.5692 80C13.3563 72.7031 7.29692 66.6437 -7.62939e-06 62.4308L36 0.0769444Z"
								fill="#DAD8D7"
								stroke="white"
								strokeWidth="2.4"
								mask="url(#path-1-inside-1_2630_35286)"
							/>
						</svg>

						{/*@TODO//Update the rotation factor to display actual indication*/}
						<div
							style={{transform: `rotate(${rotation}deg)`}}
							className={styles.needle}
						>
							<svg
								width="19"
								height="66"
								viewBox="0 0 19 66"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath={"url(#clip0_2631_462)"}>
									<path
										d="M18.7427 65.8655L9.67528 0.00067062L0.608701 65.8628C3.22141 64.1451 6.33822 63.1392 9.67768 63.1396C13.0171 63.1399 16.1333 64.1426 18.7477 65.8641L18.7427 65.8655Z"
										fill="#2C3030"
									/>
								</g>
								<defs>
									<clipPath id="clip0_2631_462">
										<rect width="19" height="66" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<svg
								className={styles.needleRoot}
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M-5.24537e-07 12C-2.35387e-07 18.615 5.38146 24 12.0023 24C18.6232 24 24 18.6196 24 12C24 5.38042 18.6185 1.09351e-06 12.0023 1.38271e-06C5.38606 1.67192e-06 -8.13887e-07 5.38042 -5.24537e-07 12Z"
									fill="#2C3030"
								/>
								<path
									d="M4 12C4 16.4144 7.59107 20 12 20C16.4089 20 20 16.4089 20 12C20 7.59106 16.4089 4 12 4C7.59106 4 4 7.59106 4 12Z"
									fill="white"
								/>
							</svg>
						</div>
					</div>

					<div className={styles.gaugelevel}>{level}</div>
				</div>
			</div>
		</LazyLoadComponent>
	);
};

export default MobilityCard;
