import styles from "./Tenure.module.scss";
import TenureCard from "./TenureCard";
import {LazyLoadComponent} from "react-lazy-load-image-component";

const dataRole = [
	{label: "Less than 1 year", value: 1},
	{label: "1 to 2 years", value: 2},
	{label: "2 to 5 years", value: 8},
	{label: "5 to 10 years", value: 5},
	{label: "More than 10 years", value: 11},
];

const dataCompany = [
	{label: "Less than 1 year", value: 0},
	{label: "1 to 2 years", value: 5},
	{label: "2 to 5 years", value: 7},
	{label: "5 to 10 years", value: 2},
	{label: "More than 10 years", value: 4},
];

const MarketReportTenure = () => {
	return (
		<LazyLoadComponent>
			<div className={styles.container}>
				<h2>Tenure</h2>
				<hr />
				<div className={styles.chartBox}>
					<TenureCard
						title={"Time in Current Role"}
						average={"8.7 Years"}
						description={"How long people have held their current position for"}
						data={dataRole}
					/>
					<TenureCard
						title={"Time in Current Company"}
						average={"3.5 Years"}
						description={"How long people have worked at their current company for"}
						data={dataCompany}
					/>
				</div>
			</div>
		</LazyLoadComponent>
	);
};

export default MarketReportTenure;
