import {
	ACTIVE_ACCORDION_ON_FILTER,
	CLEAR_SEARCHED_CANDIDATES,
	CLEAR_SEARCHED_COMPANIES,
	COMPANIES_SPECIALITIES_SELECTED_CONNECTIVITY_LOGIC,
	GET_SEARCHED_CANDIDATES_DONE,
	GET_SEARCHED_CANDIDATES_FAIL,
	GET_SEARCHED_CANDIDATES_INIT,
	GET_SEARCHED_COMPANIES_DONE,
	GET_SEARCHED_COMPANIES_FAIL,
	GET_SEARCHED_COMPANIES_INIT,
	IS_DATE_DROPDOWN_ACTIVE_ON_DE_FILTER,
	IS_SIZE_DROPDOWN_ACTIVE_ON_DE_FILTER,
	IS_TYPE_DROPDOWN_ACTIVE_ON_DE_FILTER,
	LOGOUT,
	OPEN_PROJECT_MODAL,
	REMOVE_COMPANIES_FROM_TARGET_LIST_DONE,
	RESET_CANDIDATES_FILTERS,
	RESET_COMPANIES_FILTERS,
	SET_AI_COMPANY_IDEA_FILTER_MODE,
	SET_ALL_FILTER_FOR_CANDIDATES,
	SET_CANDIDATE_COMPANY_POSITION,
	SET_CANDIDATE_FILTERS,
	SET_CANDIDATE_TARGETLIST_POSITION,
	SET_CANDIDATE_TARGETLIST_TOGGLE,
	SET_COMPANIES_FILTERS_BY_COMPANIES_LIST,
	SET_COMPANY_FILTERS,
	SET_COMPANY_TYPE_FILTER_CONFIG,
	SET_HEADCOUNT_FILTER_CONFIG,
	SET_INDUSTRIES_FILTER_CONFIG,
	SET_JOB_FUNCTION_FILTER_CONFIG,
	SET_JOB_TITLE_FILTER_MODE,
	SET_KEYWORD_FILTER_CONFIG,
	SET_LOCATIONS_FILTER_CONFIG,
	SET_MULTIPLE_FILTER_FOR_CANDIDATE,
	SET_MULTIPLE_FILTER_FOR_COMPANY,
	SET_REVENUE_FILTER_CONFIG,
	SET_SELECTED_CANDIDATES_DONE,
	SET_SELECTED_CANDIDATES_INIT,
	SET_SELECTED_COMPANIES_DONE,
	TOGGLE_ENHANCE_CANDIDATES_RESULT,
	TOGGLE_ENHANCE_COMPANIES_RESULT,
} from "store/mapx/mapXActionTypes";

export const filterInitialState = {
	selected_companies: [],
	selectedCompaniesInProgress: false,
	selectedCandidates: [],
	selectedCandidatesInProgress: false,

	enhanceCompaniesResult: false,
	company_speciality_selected_connectivity: "or", // and | or | not,

	ai_company_idea_filter_mode: "exact", // exact | suggested

	company_filters: {
		industries: [],
		size: [],
		revenue: [],
		company_type: [],

		specialties: [],
		specialties_not: [],
		specialties_and: [],
		specialties_or: [],

		countries: [],
		indexes: [],
		headquarters_countries: [],
	},

	enhanceCandidatesResult: false,
	job_title_filter_mode: "contains", // contains | close

	candidate_filters: {
		companies: [],
		current_companies: [],
		previous_companies: [],
		tl_companies: [],
		current_tl_companies: [],
		previous_tl_companies: [],

		candidates: [],

		linkedin_url: null,
		current_seniority: [],

		specialisms_and: [],
		specialisms_or: [],
		specialisms_not: [],

		current_specialisms_and: [],
		current_specialisms_or: [],
		current_specialisms_not: [],

		previous_specialisms_and: [],
		previous_specialisms_or: [],
		previous_specialisms_not: [],

		job_function_selection_tree: {
			current: {},
			previous: {},
			any: {},
		},

		text_keywords_or: [],
		text_keywords_and: [],
		text_keywords_not: [],

		current_text_keywords_or: [],
		current_text_keywords_and: [],
		current_text_keywords_not: [],

		previous_text_keywords_or: [],
		previous_text_keywords_and: [],
		previous_text_keywords_not: [],

		languages: [],
		headquarters_countries: [],
		gender_diversity_importance: null, // Low/Medium/High
		ethnic_diversity_importance: null, // Low/Medium/High

		countries_or: [],
		previous_countries_or: [],
		current_countries_or: [],

		regions_or: [],
		previous_regions_or: [],
		current_regions_or: [],

		industries: [],
		previous_industries: [],
		current_industries: [],

		specialties: [],
		target_list: null,

		revenue: [],
		current_revenue: [],
		previous_revenue: [],

		company_size: [],
		current_company_size: [],
		previous_company_size: [],

		company_type: [],
		previous_company_type: [],
		current_company_type: [],

		company_events: {
			types: [],
			size: [],
			dates: [],
		},
	},

	candidate_filters_position: {
		companies: "current",
		target_list: "current",
		target_list_toggle: false,
	},

	candidate_company_headcount_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_company_revenue_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_company_type_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_industries_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_location_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_keyword_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
		active_current_position_connect_logic: "or", // "and" | "or" | "not"
		active_previous_position_connect_logic: "or", // "and" | "or" | "not"
		active_any_position_connect_logic: "or", // "and" | "or" | "not"
	},

	candidate_job_function_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
		active_current_position_connect_logic: "or", // "and" | "or" | "not"
		active_previous_position_connect_logic: "or", // "and" | "or" | "not"
		active_any_position_connect_logic: "or", // "and" | "or" | "not"
	},

	searchedCompanies: {results: [], pagination: null},

	searchedCompaniesError: false,
	searchedCompaniesInProgress: false,
	selectedCompaniesError: false,

	searchedCandidates: {
		results: [],
		pagination: null,
	},
	searchedCandidatesError: false,
	searchedCandidatesInProgress: false,
	selectedCandidatesError: false,
	activeAccordionOnFilter: null,
	isTypeDropDownActiveOnDEFilter: false,
	isSizeDropDownActiveOnDEFilter: false,
	isDateDropDownActiveOnDEFilter: false,
	isOpenProjectModal: false,
};

const filterReducer = (state = {...filterInitialState}, action) => {
	switch (action.type) {
		case COMPANIES_SPECIALITIES_SELECTED_CONNECTIVITY_LOGIC:
			return {...state, company_speciality_selected_connectivity: action.payload};
		case REMOVE_COMPANIES_FROM_TARGET_LIST_DONE:
			return {
				...state,
				candidate_filters: {
					...state.candidate_filters,
					tl_companies: [],
					current_tl_companies: [],
					previous_tl_companies: [],
				},
			};

		case SET_CANDIDATE_TARGETLIST_TOGGLE:
			return {
				...state,
				...state.candidate_filters_position,
				candidate_filters_position: {
					...state.candidate_filters_position,
					target_list_toggle: action.payload,
				},
			};
		case SET_CANDIDATE_TARGETLIST_POSITION:
			return {
				...state,
				...state.candidate_filters_position,
				candidate_filters_position: {
					...state.candidate_filters_position,
					target_list: action.payload,
				},
			};
		case SET_CANDIDATE_COMPANY_POSITION:
			return {
				...state,
				...state.candidate_filters_position,
				candidate_filters_position: {
					...state.candidate_filters_position,
					companies: action.payload,
				},
			};
		case RESET_COMPANIES_FILTERS:
			return {
				...state,
				company_filters: {...filterInitialState.company_filters},
				selected_companies: [],
				enhanceCompaniesResult: false,
			};
		case RESET_CANDIDATES_FILTERS:
			return {
				...state,
				candidate_filters: {...filterInitialState.candidate_filters},
				selectedCandidates: [],
				target_list_in_use_for_filter: true,
				enhanceCandidatesResult: false,
			};

		case SET_AI_COMPANY_IDEA_FILTER_MODE:
			return {
				...state,
				ai_company_idea_filter_mode: action.payload,
			};

		case SET_COMPANY_FILTERS:
			return {
				...state,
				company_filters: {
					...state.company_filters,
					[action.payload.type]: [...action.payload.values],
				},
			};
		case SET_COMPANIES_FILTERS_BY_COMPANIES_LIST:
			return {
				...state,
				company_filters: {...state.company_filters, ...action.payload},
			};
		case SET_MULTIPLE_FILTER_FOR_COMPANY:
			return {
				...state,
				company_filters: {
					...state.company_filters,
					...action.payload,
				},
			};
		case SET_ALL_FILTER_FOR_CANDIDATES:
			return {
				...state,
				candidate_filters: {...filterInitialState.candidate_filters, ...action.payload},
			};
		case SET_CANDIDATE_FILTERS:
			return {
				...state,
				candidate_filters: {
					...state.candidate_filters,
					[action.payload.type]: action.payload.values,
				},
			};
		case SET_MULTIPLE_FILTER_FOR_CANDIDATE:
			return {
				...state,
				candidate_filters: {
					...state.candidate_filters,
					...action.payload,
				},
			};

		case SET_JOB_FUNCTION_FILTER_CONFIG:
			return {
				...state,
				candidate_job_function_filter_config: {
					...state.candidate_job_function_filter_config,
					...action.payload,
				},
			};

		case SET_INDUSTRIES_FILTER_CONFIG:
			return {
				...state,
				candidate_industries_filter_config: {
					...state.candidate_industries_filter_config,
					...action.payload,
				},
			};

		case SET_LOCATIONS_FILTER_CONFIG:
			return {
				...state,
				candidate_location_filter_config: {
					...state.candidate_location_filter_config,
					...action.payload,
				},
			};

		case SET_KEYWORD_FILTER_CONFIG:
			return {
				...state,
				candidate_keyword_filter_config: {
					...state.candidate_keyword_filter_config,
					...action.payload,
				},
			};

		case SET_JOB_TITLE_FILTER_MODE:
			return {
				...state,
				job_title_filter_mode: action.payload,
			};

		case SET_HEADCOUNT_FILTER_CONFIG:
			return {
				...state,
				candidate_company_headcount_filter_config: {
					...state.candidate_company_headcount_filter_config,
					...action.payload,
				},
			};

		case SET_REVENUE_FILTER_CONFIG:
			return {
				...state,
				candidate_company_revenue_filter_config: {
					...state.candidate_company_revenue_filter_config,
					...action.payload,
				},
			};

		case SET_COMPANY_TYPE_FILTER_CONFIG:
			return {
				...state,
				candidate_company_type_filter_config: {
					...state.candidate_company_type_filter_config,
					...action.payload,
				},
			};

		// SEARCH COMPANIES
		case GET_SEARCHED_COMPANIES_INIT:
			return {...state, searchedCompaniesInProgress: true};
		case GET_SEARCHED_COMPANIES_DONE:
			let companyResults;
			const companyPagination = action.payload.pagination;

			if (companyPagination.page === 1) {
				companyResults = action.payload.results;
			} else {
				companyResults = [...state.searchedCompanies.results, ...action.payload.results];
			}

			return {
				...state,
				searchedCompanies: {
					results: companyResults,
					pagination: companyPagination,
				},
				searchedCompaniesInProgress: false,
			};
		case GET_SEARCHED_COMPANIES_FAIL:
			return {
				...state,
				searchedCompaniesError: action.payload,
				searchedCompaniesInProgress: false,
			};

		// SET SELECTED COMPANIES
		case SET_SELECTED_COMPANIES_DONE:
			return {
				...state,
				selectedCompaniesInProgress: false,
				company_filters: {...filterInitialState.company_filters},
				// candidate_filters: {...filterInitialState.candidate_filters},
				selected_companies: [...action.payload],
				selectedCandidates: [],
			};

		// SELECTED CANDIDATES
		case SET_SELECTED_CANDIDATES_INIT:
			return {...state, selectedCandidatesInProgress: true};

		// SEARCH CANDIDATES
		case GET_SEARCHED_CANDIDATES_INIT:
			return {...state, searchedCandidatesInProgress: true};
		case GET_SEARCHED_CANDIDATES_DONE:
			let candidateResults;
			const candidatePagination = action.payload.pagination;

			if (candidatePagination.page === 1) {
				candidateResults = action.payload.results;
			} else {
				candidateResults = [...state.searchedCandidates.results, ...action.payload.results];
			}

			return {
				...state,
				searchedCandidates: {
					results: candidateResults,
					pagination: candidatePagination,
				},
				searchedCandidatesInProgress: false,
			};

		case CLEAR_SEARCHED_CANDIDATES:
			return {
				...state,
				searchedCandidates: {
					results: [],
					pagination: null,
				},
			};

		case CLEAR_SEARCHED_COMPANIES:
			return {
				...state,
				searchedCompanies: {
					results: [],
					pagination: null,
				},
			};
		case GET_SEARCHED_CANDIDATES_FAIL:
			return {...state, searchedCandidatesError: action.payload};
		case SET_SELECTED_CANDIDATES_DONE:
			return {
				...state,
				// candidate_filters: {
				// 	...filterInitialState.candidate_filters,
				// 	filters_position: {
				// 		...state.candidate_filters.filters_position,
				// 		target_list_toggle: false, // When candidate selected we turn off company targetListApi list
				// 	},
				// },
				selectedCandidates: [...action.payload],
				selectedCandidatesInProgress: false,
			};

		case TOGGLE_ENHANCE_COMPANIES_RESULT:
			return {
				...state,
				enhanceCompaniesResult: action.payload,
			};
		case TOGGLE_ENHANCE_CANDIDATES_RESULT:
			return {
				...state,
				enhanceCandidatesResult: action.payload,
			};

		case ACTIVE_ACCORDION_ON_FILTER:
			return {
				...state,
				activeAccordionOnFilter: action.payload,
			};

		case IS_TYPE_DROPDOWN_ACTIVE_ON_DE_FILTER:
			return {
				...state,
				isTypeDropDownActiveOnDEFilter: action.payload,
			};

		case IS_SIZE_DROPDOWN_ACTIVE_ON_DE_FILTER:
			return {
				...state,
				isSizeDropDownActiveOnDEFilter: action.payload,
			};

		case IS_DATE_DROPDOWN_ACTIVE_ON_DE_FILTER:
			return {
				...state,
				isDateDropDownActiveOnDEFilter: action.payload,
			};

		case OPEN_PROJECT_MODAL:
			return {
				...state,
				isOpenProjectModal: !state.isOpenProjectModal,
			};
		case LOGOUT:
			return {
				...state,
				...filterInitialState,
				company_filters: {
					...state.company_filters,
					...filterInitialState.company_filters,
				},
				candidate_filters: {
					...state.candidate_filters,
					...filterInitialState.candidate_filters,
					tl_companies: [],
					current_tl_companies: [],
					previous_tl_companies: [],
					target_list: null,
				},
			};

		default:
			return state;
	}
};

export default filterReducer;
