import React, {useCallback, useEffect, useMemo, useState} from "react";

import styles from "./styles.module.scss";
import PeopleFilters from "containers/Filters/PeopleFilters";
import {candidateFiltersSelector} from "store/mapx/filter/filterSelectors";
import {ActionAltButton, ProjectCandidateCard} from "mapx-components";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import {SkeletonLoaderCardAsList} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {
	candidatesInProgressSelector,
	candidatesPaginationInfoSelector,
	candidatesSelector,
	filteredCandidatesInProgressSelector,
	importLinkedInCandidateInProgressSelector,
} from "store/mapx/candidate/candidateSelectors";
import {
	getCandidatesData,
	getFilteredCandidatesData,
} from "store/mapx/candidate/candidateResultsAsyncActions";
import {
	resetCandidatesFilters,
	setCandidateTargetListToggle,
} from "store/mapx/filter/filterActions";
import {
	ICandidateSearchApiRequestPayload,
	TCandidateApiFilterSelection,
} from "api/candidateApi/form";
import {SICandidate} from "api/candidateApi/types";
import classNames from "classnames";
import {ArrowDown, ClearSelectionIcon, SavePlusIcon} from "assets/icons";
import {saveCandidatesByFilters} from "store/mapx/target-list/targetListAsyncActions";
import {toggleAllTargetCompaniesOnCandidateFilter} from "store/mapx/filter/candidateSearchPageAsyncActions";
import css from "../../Projects/projects.module.scss";
import Pagination from "mapx-components/Pagination";
import {cancelRequest} from "api/cancelTokens";
import {GET_CANDIDATES_SEARCH_PAGE_TOKEN} from "api/requestCancelTokenStrings";
import {setCandidatesPaginationInfo} from "store/mapx/candidate/candidateActions";

const CandidateFilterSearch = ({
	candidatesResultCount,
	shouldTrack = true,
}: {
	candidatesResultCount: string | number;
	shouldTrack?: boolean;
}) => {
	const dispatch = useAppDispatch();

	const [savingAll, setSavingAll] = useState<boolean>(false);

	const [requestCancelled, setRequestCancelled] = useState(false);

	const [displayAllExperience, setDisplayAllExperience] = useState<boolean>(false);

	const [loadingMoreResults, setLoadingMoreResults] = useState(false);

	const candidatesInProgress = useAppSelector(candidatesInProgressSelector);

	const filteredCandidatesInProgress = useAppSelector(filteredCandidatesInProgressSelector);

	const filterSelection = useAppSelector(candidateFiltersSelector);

	const candidates = useAppSelector(candidatesSelector);

	const pagination = useAppSelector(candidatesPaginationInfoSelector);

	const importingLinkedInCandidateInProgress = useAppSelector(
		importLinkedInCandidateInProgressSelector,
	);

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...filterSelection});

		if (payload) {
			return {...payload.filters, ...payload.sorting_options};
		} else return null;
	}, [filterSelection]);

	const fetchResults = useCallback(
		async (
			payload: Nullable<TCandidateApiFilterSelection>,
			page: number,
			perPage: number,
		): Promise<string> => {
			let response;
			const pagination = {page: page, per_page: perPage};

			if (payload) {
				const apiPayload = buildCandidateApiRequestPayload(payload) as Omit<
					ICandidateSearchApiRequestPayload,
					"pagination"
				>;
				response = await dispatch(getFilteredCandidatesData(apiPayload, pagination));
			} else {
				response = await dispatch(getCandidatesData(pagination));
			}

			setLoadingMoreResults(false);

			if (response && response.cancelled) {
				setRequestCancelled(true);

				return "cancelled";
			} else {
				setRequestCancelled(false);

				return "success";
			}
		},
		[dispatch],
	);

	useEffect(() => {
		if (candidateFilterPayload !== null) {
			fetchResults(candidateFilterPayload, pagination?.page, pagination?.per_page);
		} else {
			fetchResults(null, pagination?.page, pagination?.per_page);
		}
	}, [candidateFilterPayload, fetchResults, pagination?.page, pagination?.per_page]);

	useEffect(() => {
		dispatch(toggleAllTargetCompaniesOnCandidateFilter(true));

		return () => {
			dispatch(setCandidateTargetListToggle(false));
			cancelRequest(GET_CANDIDATES_SEARCH_PAGE_TOKEN);
		};
	}, [dispatch]);

	useEffect(() => {
		return () => {
			dispatch(
				setCandidatesPaginationInfo({
					page: 1,
					per_page: 20,
				}),
			);
		};
	}, [dispatch, candidateFilterPayload]);

	const onPageChange = async (currentPageNumber: number) => {
		dispatch(setCandidatesPaginationInfo({...pagination, page: currentPageNumber}));

		setLoadingMoreResults(true);
	};

	const onPerPageChange = async (count: number) => {
		dispatch(setCandidatesPaginationInfo({...pagination, per_page: count}));
	};

	const candidatesFetchInProgress = useMemo(
		() =>
			candidatesInProgress ||
			filteredCandidatesInProgress ||
			requestCancelled ||
			loadingMoreResults,
		[candidatesInProgress, filteredCandidatesInProgress, requestCancelled, loadingMoreResults],
	);

	const resetFilters = useCallback(() => {
		dispatch(resetCandidatesFilters());
	}, [dispatch]);

	const handleAddAllCandidatesToProject = useCallback(async () => {
		const hasFilter = !!candidateFilterPayload;

		if (!hasFilter) {
			window.alert("Please apply filters.");
		} else if (hasFilter && candidates?.length > 10000) {
			window.alert(
				"Candidates search result count exceeds limit. Please consider narrowing down your search.",
			);
		} else {
			const apiPayload = buildCandidateApiRequestPayload(candidateFilterPayload) as Omit<
				ICandidateSearchApiRequestPayload,
				"pagination"
			>;

			setSavingAll(true);
			await dispatch(saveCandidatesByFilters(apiPayload));
			setSavingAll(false);
		}
	}, [candidateFilterPayload, candidates?.length, dispatch]);

	const isAddAllButtonVisibleForCandidates = useMemo(() => {
		return pagination?.count > 0 && pagination?.count < 10001;
	}, [pagination?.count]);

	return (
		<div className={styles.container}>
			<PeopleFilters />

			<div className={styles.resultsWrapper}>
				<div className={styles.headerSectionWrapper}>
					<div className={styles.actionButtonsRight}>
						<ActionAltButton
							key={"expand"}
							customClass={classNames(styles.toggleExpandButton, {
								[styles.reverse]: displayAllExperience,
							})}
							iconComponent={<ArrowDown />}
							handleClick={() => setDisplayAllExperience((p) => !p)}
							title={displayAllExperience ? "Collapse All" : "Expand All"}
						/>

						{!!candidateFilterPayload && (
							<ActionAltButton
								key={"clear_filter"}
								customClass={classNames(styles.resetFilterButton)}
								iconComponent={<ClearSelectionIcon />}
								handleClick={resetFilters}
								title={"Clear Filters"}
							/>
						)}

						<div className={styles.count}>Results: {candidatesResultCount}</div>
					</div>

					<div className={styles.actionButtonsLeft}>
						{isAddAllButtonVisibleForCandidates && (
							<ActionAltButton
								key={"add_all_to_projects"}
								customClass={classNames(styles.toggleExpandButton, {
									[styles.reverse]: displayAllExperience,
								})}
								loading={savingAll}
								disabled={savingAll}
								iconComponent={<SavePlusIcon />}
								handleClick={handleAddAllCandidatesToProject}
								title={
									savingAll ? "Adding all to project..." : "Add all to project"
								}
							/>
						)}
					</div>
				</div>

				<LazyLoadComponent>
					{!candidatesFetchInProgress &&
						candidates?.map((candidate: SICandidate, index: number) => {
							return (
								<ProjectCandidateCard
									key={`${candidate.id}${index}`}
									candidate={candidate}
									searchType="People Search"
									displayAllExperience={displayAllExperience}
									displayDealExperience={false}
									shouldTrack={shouldTrack}
								/>
							);
						})}
				</LazyLoadComponent>

				{candidatesFetchInProgress && (
					<SkeletonLoaderCardAsList count={pagination?.per_page} />
				)}

				{!candidatesFetchInProgress && pagination && pagination?.count >= 10 && (
					<div className={css.paginationWrapper}>
						<Pagination
							currentPage={pagination?.page}
							resultsCount={pagination?.count}
							totalPages={pagination?.pages}
							perPage={pagination?.per_page}
							onPageChange={onPageChange}
							onPerPageChange={onPerPageChange}
						/>
					</div>
				)}

				{!candidates?.length &&
					!importingLinkedInCandidateInProgress &&
					!candidatesFetchInProgress && (
						<div className={styles.noResult}>
							Hmm, I have not found any results &#128542;. Try to re-configure your
							search criteria.
						</div>
					)}
			</div>
		</div>
	);
};

export default CandidateFilterSearch;
