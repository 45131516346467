import {HorizontalBar} from "react-chartjs-2";
import styles from "../Diversity.module.scss";
import {TMostDiversePathWaysProps} from "../../types";

const MostDiversePathways = ({data}: TMostDiversePathWaysProps) => {
	const options = {
		indexAxis: "y" as const,
		responsive: true,
		legend: {
			display: false,
		},
		datalabels: {
			anchor: "end",
			align: "end",
			labels: {
				value: {
					color: "blue",
				},
			},
		},
		plugins: {
			datalabels: {
				anchor: "end",
				align: "end",
				labels: {
					value: {
						color: "blue",
					},
				},
			},
		},
		scales: {
			x: {
				beginAtZero: true,
				ticks: {
					stepSize: 10,
				},
			},
			y: {
				ticks: {
					autoSkip: true,
				},
			},
		},
	};

	return (
		<div style={{padding: "0 30px"}}>
			<h4 className={styles.barChartTitle}>
				The number of diverse candidates that have come from function
			</h4>
			<HorizontalBar data={data} options={options} />
		</div>
	);
};

export default MostDiversePathways;
