import {useCallback, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {Checkbox, ResetFilters} from "components";
import {
	isDateDropDownActiveOnDEFilterSelector,
	isSizeDropDownActiveOnDEFilterSelector,
	isTypeDropDownActiveOnDEFilterSelector,
} from "store/mapx/filter/DealExperienceFilterSelectors";
import {
	setIsDateDropDownActiveOnDEFilter,
	setIsSizeDropDownActiveOnDEFilter,
	setIsTypeDropDownActiveOnDEFilter,
} from "store/mapx/filter/filterActions";
import useFetchListOptions from "hooks/useFetchListOptions";
import {
	getCandidateCompanyDateRanges,
	getCandidateCompanyEventRanges,
} from "store/mapx/search/searchAsyncActions";
import styles from "./styles.module.scss";
import {
	TCandidateCompanyDateRangeItem,
	TCandidateCompanyEventSizeItem,
	TCandidateCompanyEventTypeItem,
	TDealExperienceSearchFilterProps,
} from "./types";
import useFetchListPaginateOptions from "hooks/useFetchListPaginateOptions";
import FilterSection from "./filterSection";
import {
	getDateRangeLabel,
	getEventSizeLabel,
} from "containers/Filters/PeopleFilters/DealExperienceFilter/utils";
import {
	CandidateCompanyDateRangeOptionsSelector,
	candidateCompanyEventPaginationInfoSelector,
	CandidateCompanyEventSizeRangeOptionsSelector,
	CandidateCompanyEventsSelector,
} from "store/mapx/search/searchDealExperienceSelectors";

const DealExperienceSearchFilter = ({
	handleResetClick,
	handleOnChange,
	candidateCompanyTypesSearchQuery,
	setCandidateCompanyTypesQueryOnState,
	apiCall,
	handleBulkSelect,
	selectedDealExperienceItems,
}: TDealExperienceSearchFilterProps) => {
	const dispatch = useAppDispatch();
	const isSizeDropDownActiveOnDEFilter = useAppSelector(isSizeDropDownActiveOnDEFilterSelector);
	const isDateDropDownActiveOnDEFilter = useAppSelector(isDateDropDownActiveOnDEFilterSelector);
	const isTypeDropDownActiveOnDEFilter = useAppSelector(isTypeDropDownActiveOnDEFilterSelector);

	const candidateCompanyEventPaginationInfo = useAppSelector(
		candidateCompanyEventPaginationInfoSelector,
	);

	const candidateCompanyEventOptions = useAppSelector(CandidateCompanyEventsSelector);

	const candidateCompanyEventSizeRangeOptions = useAppSelector(
		CandidateCompanyEventSizeRangeOptionsSelector,
	);

	const candidateCompanyDateRangeOptions = useAppSelector(
		CandidateCompanyDateRangeOptionsSelector,
	);

	const companyDateRange = useFetchListOptions({
		options: candidateCompanyDateRangeOptions,
		callbackMethod: getCandidateCompanyDateRanges,
	});

	const companyEventSizeRange = useFetchListOptions({
		options: candidateCompanyEventSizeRangeOptions,
		callbackMethod: getCandidateCompanyEventRanges,
	});

	const {loading, listInnerRef, page, setPage, data} = useFetchListPaginateOptions({
		options: candidateCompanyEventOptions,
		apiCall,
		initialSearchQuery: candidateCompanyTypesSearchQuery,
		setQueryCallbackOnState: setCandidateCompanyTypesQueryOnState,
	});

	const displayResetFilterOption = useMemo(() => {
		return (
			selectedDealExperienceItems.types.length > 0 ||
			selectedDealExperienceItems.sizes.length > 0 ||
			selectedDealExperienceItems.dates.length > 0
		);
	}, [
		selectedDealExperienceItems.types.length,
		selectedDealExperienceItems.sizes.length,
		selectedDealExperienceItems.dates.length,
	]);

	const onScroll = useCallback(() => {
		try {
			if (!!setPage && !loading && candidateCompanyEventPaginationInfo?.pages > page) {
				if (listInnerRef.current) {
					const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
					if (Math.ceil(scrollTop) + clientHeight >= scrollHeight - 1) {
						setPage(candidateCompanyEventPaginationInfo.page + 1);
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [setPage, loading, candidateCompanyEventPaginationInfo, page, listInnerRef]);

	return (
		<div>
			<div style={{margin: "10px 0"}}>
				{displayResetFilterOption && (
					<ResetFilters
						parentStyle={{color: "#5A5A5A", marginRight: 19}}
						onClick={() => handleResetClick("all")}
						displayIcon={true}
					>
						Clear All
					</ResetFilters>
				)}
			</div>

			<FilterSection<TCandidateCompanyEventTypeItem>
				label="Experience of event type"
				isActive={isTypeDropDownActiveOnDEFilter}
				selectedValues={selectedDealExperienceItems.types}
				count={selectedDealExperienceItems.types.length}
				toggleActive={() =>
					dispatch(setIsTypeDropDownActiveOnDEFilter(!isTypeDropDownActiveOnDEFilter))
				}
				data={data as TCandidateCompanyEventTypeItem[]}
				onScroll={onScroll}
				listInnerRef={listInnerRef}
				handleBulkSelect={handleBulkSelect.selectTypes}
				showSelectAll={data?.length !== selectedDealExperienceItems?.types?.length}
				handleResetClick={handleResetClick}
				resetFilterOption="types"
				renderItem={(s) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={styles.checkboxContainer}
						isChecked={selectedDealExperienceItems.types?.includes(s.name) || false}
						key={s.id}
						label={`${s.name}`}
						onChange={() => handleOnChange.onTypeChange(s)}
						value={s.name}
					/>
				)}
				loading={loading}
			/>

			<FilterSection<TCandidateCompanyEventSizeItem>
				label="Event size"
				isActive={isSizeDropDownActiveOnDEFilter}
				selectedValues={[getEventSizeLabel(selectedDealExperienceItems.sizes)]}
				count={selectedDealExperienceItems.sizes?.length}
				toggleActive={() =>
					dispatch(setIsSizeDropDownActiveOnDEFilter(!isSizeDropDownActiveOnDEFilter))
				}
				showSelectAll={false}
				data={companyEventSizeRange.filteredData}
				onScroll={onScroll}
				listInnerRef={listInnerRef}
				handleResetClick={handleResetClick}
				resetFilterOption="size"
				renderItem={(s) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={styles.checkboxContainer}
						isChecked={selectedDealExperienceItems.sizes?.includes(s.id)}
						key={s.id}
						label={`${s.name}`}
						onChange={() => handleOnChange.onSizeChange(s)}
						value={s.name}
					/>
				)}
				loading={false}
			/>

			<FilterSection<TCandidateCompanyDateRangeItem>
				label="Date range"
				isActive={isDateDropDownActiveOnDEFilter}
				selectedValues={[getDateRangeLabel(selectedDealExperienceItems.dates)]}
				count={selectedDealExperienceItems.dates?.length}
				toggleActive={() =>
					dispatch(setIsDateDropDownActiveOnDEFilter(!isDateDropDownActiveOnDEFilter))
				}
				showSelectAll={
					companyDateRange.filteredData?.length !==
					selectedDealExperienceItems.dates?.length
				}
				data={companyDateRange.filteredData}
				onScroll={onScroll}
				listInnerRef={listInnerRef}
				handleResetClick={handleResetClick}
				handleBulkSelect={handleBulkSelect.selectDates}
				resetFilterOption="dates"
				renderItem={(s) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={styles.checkboxContainer}
						isChecked={getDateRangeLabel(selectedDealExperienceItems.dates)?.includes(
							s.name,
						)}
						key={s.id}
						label={`${s.name}`}
						onChange={() => handleOnChange.onDateChange(s)}
						value={s.name}
					/>
				)}
				loading={false}
			/>
		</div>
	);
};

export default DealExperienceSearchFilter;
