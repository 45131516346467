import {TAppDispatch} from "types";
import {IMarketReport, TCreateMarketReportForm} from "api/marketReportApi/types";
import marketReportApi from "api/marketReportApi";
import {successResponse} from "helpers/map";
import axios, {AxiosResponse} from "axios";
import {
	createMarketReportDone,
	createMarketReportFail,
	createMarketReportInit,
	getMarketReportsDone,
	getMarketReportsFail,
	getMarketReportsInit,
	updateMarketReportDone,
	updateMarketReportFail,
	updateMarketReportInit,
} from "store/mapx/market-report/marketReportActions";
import {clearCancelToken, getCancelToken} from "api/cancelTokens";
import {GET_MARKET_REPORTS_TOKEN} from "api/requestCancelTokenStrings";
import {ATPagination} from "api/types";

export const createMarketReport =
	(payload: TCreateMarketReportForm) => async (dispatch: TAppDispatch) => {
		try {
			dispatch(createMarketReportInit());

			const response: AxiosResponse = await marketReportApi.createMarketReport(payload);

			if (successResponse(response, 201)) {
				const data: IMarketReport = response.data;

				dispatch(createMarketReportDone(data));

				return data;
			}

			return null;
		} catch (e) {
			console.log(e);

			dispatch(createMarketReportFail(e));

			return null;
		}
	};

export const updateMarketReport =
	(payload: TCreateMarketReportForm, id: number) => async (dispatch: TAppDispatch) => {
		try {
			dispatch(updateMarketReportInit());

			const response: AxiosResponse = await marketReportApi.updateMarketReport(id, payload);

			if (successResponse(response, 200)) {
				const data: IMarketReport = response.data;

				dispatch(updateMarketReportDone(data));

				return data;
			}

			return null;
		} catch (e) {
			console.log(e);

			dispatch(updateMarketReportFail(e));

			return null;
		}
	};

export const getMarketReports =
	(name = "", createdBy: ("self" | "organisation" | "mapx")[] = [], page = 1, perPage = 20) =>
	async (dispatch: TAppDispatch) => {
		try {
			dispatch(getMarketReportsInit());

			const payload = {
				page: page,
				per_page: perPage,
				name,
				created_by: createdBy,
			};

			// Remove falsy values
			Object.keys(payload).forEach((k) => {
				const key = k as keyof typeof payload;
				if (!payload[key]) delete payload[key];
				if (
					Array.isArray(payload[key]) &&
					(payload[key] as ("self" | "organisation" | "mapx")[]).length === 0
				) {
					delete payload[key];
				}
			});

			const response: AxiosResponse = await marketReportApi.getMarketReports(payload, {
				cancelToken: getCancelToken(GET_MARKET_REPORTS_TOKEN).token,
			});

			if (successResponse(response, 200)) {
				const data: {results: IMarketReport[]; pagination: ATPagination} = response.data;

				dispatch(getMarketReportsDone(data, name, createdBy));
			}
		} catch (e) {
			if (axios.isCancel(e)) {
				console.log("Request canceled", e.message);
			} else {
				dispatch(getMarketReportsFail(e));
			}
		} finally {
			clearCancelToken(GET_MARKET_REPORTS_TOKEN);
		}
	};
