import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styles from "./styles.module.scss";
import {DropdownProps} from "components/Inputs/Dropdown/type";
import classNames from "classnames";
import {useOnClickOutside} from "hooks";
import ArrowRight from "assets/icons/ArrowRight";
import Loader from "components/Plugins/Loader";

const Dropdown = <T,>({
	options,
	placeholder = "Select an option",
	onSelect,
	getOptionLabel,
	isOptionValueNull,
	getOptionIcon,
	getChildrenItems,
	hasSeparator,
	dropdownButtonClass,
	dropdownMenuClass,
	defaultSelectedOption = null,
	isLoading,
	dropdownButtonWidth,
	dropdownAlign = "right",
}: DropdownProps<T>) => {
	const ref = useRef<Nullable<HTMLDivElement>>(null);
	const [dropdownListPosition, setDropdownListPosition] = useState(40);
	const [dropdownListWidth, setDropdownListWidth] = useState(180);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState<Nullable<T>>(defaultSelectedOption);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleSelect = (option: T) => {
		setSelectedOption(option);
		setIsOpen(false);
		onSelect(option);
	};

	useOnClickOutside(ref, () => setIsOpen(false));

	useEffect(() => {
		if (ref?.current && !dropdownButtonWidth) {
			setDropdownListPosition(ref.current.clientHeight + 1);
			setDropdownListWidth(ref.current.clientWidth);
		}
	}, [dropdownButtonWidth]);

	// Utility to determine if there is enough space on the right
	const calculateChildDropdownPosition = useCallback(
		(dropdownElement: HTMLLIElement, isParent = false): React.CSSProperties => {
			if (dropdownElement) {
				const rect = dropdownElement.getBoundingClientRect();
				const viewportWidth = window.innerWidth;

				// If there isn't enough space on the right, position the child dropdown to the left
				const fitsOnRight = rect.right + dropdownListWidth <= viewportWidth;
				const fitsOnLeft = rect.left + dropdownListWidth <= viewportWidth;

				if (isParent) {
					return {
						left:
							dropdownAlign === "right"
								? fitsOnLeft
									? "0"
									: "auto"
								: fitsOnLeft
								? "auto"
								: "0",
						right:
							dropdownAlign === "right"
								? fitsOnRight
									? "auto"
									: "100%"
								: fitsOnRight
								? "0"
								: "auto",
					};
				} else {
					return {
						left:
							dropdownAlign === "right"
								? fitsOnLeft
									? "100%"
									: "auto"
								: fitsOnLeft
								? "-100%"
								: "auto",
						right:
							dropdownAlign === "right"
								? fitsOnRight
									? "auto"
									: "100%"
								: fitsOnRight
								? "100%"
								: "auto",
					};
				}
			} else {
				return {
					left: "100%",
					right: "auto",
				};
			}
		},
		[dropdownAlign, dropdownListWidth],
	);

	const InlineLoaderComponent = <Loader height={16} width={16} color={"#4E5555"} type={"Oval"} />;

	const hasChildren = useMemo(() => {
		if (options?.length > 0 && getChildrenItems) {
			for (const option of options) {
				if (getChildrenItems(option)?.length > 0) {
					return true;
				}
			}
		}

		return false;
	}, [getChildrenItems, options]);

	const renderEndContent = useMemo(() => {
		const endContent = (option: T) =>
			isOptionValueNull && isOptionValueNull(option) ? (
				<div className={styles.endContentWrapper}>
					{getOptionIcon && getOptionIcon(option)}
				</div>
			) : null;

		return endContent;
	}, [isOptionValueNull, getOptionIcon]);

	return (
		<div className={classNames(styles.dropdown)} ref={ref}>
			<button
				role="dropdown-button"
				className={classNames(
					styles.dropdownToggle,
					dropdownButtonClass && dropdownButtonClass,
				)}
				aria-label={isOpen ? "open" : "closed"}
				onClick={handleToggle}
				data-testid="dropdown-button"
			>
				{placeholder}
			</button>

			<ul
				className={classNames(styles.dropdownMenu, dropdownMenuClass && dropdownMenuClass, {
					[styles.open]: isOpen,
					[styles.displayScroll]: !hasChildren,
				})}
				style={{
					top: dropdownListPosition,
					width: dropdownButtonWidth ? dropdownButtonWidth : dropdownListWidth,
					...calculateChildDropdownPosition(
						document.querySelector(`[data-testid="dropdown-button"]`) as HTMLLIElement,
						true,
					),
				}}
			>
				{options.map((option, index) => (
					<li
						key={index}
						data-testid={getOptionLabel(option)}
						onClick={() => handleSelect(option)}
						className={classNames(styles.listItem)}
					>
						<div
							className={classNames(styles.dropdownItem, {
								[styles.endContent]: isOptionValueNull && isOptionValueNull(option),
								[styles.selectedItem]:
									selectedOption &&
									getOptionLabel(selectedOption) === getOptionLabel(option),
							})}
						>
							{renderEndContent(option)} {getOptionLabel(option)}{" "}
							{isLoading && isLoading(option) && InlineLoaderComponent}
							{!(isLoading && isLoading(option)) &&
								getChildrenItems !== undefined &&
								getChildrenItems(option)?.length > 0 && (
									<ArrowRight className={styles.hasChildrenIcon} />
								)}
							{!(isLoading && isLoading(option)) &&
								getChildrenItems !== undefined &&
								getChildrenItems(option)?.length > 0 && (
									<ul
										className={styles.childrenList}
										style={calculateChildDropdownPosition(
											document.querySelector(
												`[data-testid="${getOptionLabel(option)}"]`,
											) as HTMLLIElement,
										)}
									>
										{getChildrenItems(option).map((child: T, index) => (
											<li
												key={index}
												data-testid={getOptionLabel(child)}
												onClick={() => handleSelect(child)}
												className={classNames(styles.listItem, {
													[styles.childEndContent]:
														isOptionValueNull &&
														isOptionValueNull(child),
												})}
											>
												<div
													className={classNames(styles.dropdownItem, {
														[styles.selectedItem]:
															selectedOption &&
															getOptionLabel(selectedOption) ===
																getOptionLabel(child),
													})}
												>
													{renderEndContent(child)}{" "}
													{getOptionLabel(child)}{" "}
												</div>
											</li>
										))}
									</ul>
								)}
						</div>

						{hasSeparator !== undefined && hasSeparator(option) && (
							<div className={styles.separator} />
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default Dropdown;
