import styles from "./ReleventInfoSection.module.scss";
import React from "react";
import ArrowRightIcon from "../Icons/ArrowRightIcon";

const dummyCompanies = [
	{
		id: 0,
		name: "test company",
		logo_url: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png",
	},
	{
		id: 1,
		name: "test company 2",
		logo_url: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png",
	},
	{
		id: 2,
		name: "test company 3",
		logo_url: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png",
	},
	{
		id: 3,
		name: "test company 4",
		logo_url: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png",
	},
	{
		id: 4,
		name: "test company 5",
		logo_url: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png",
	},
];

const RelevantInfo = () => {
	const onViewPeople = () => {
		// @TODO onViewPeople functionality
	};

	const onViewCompany = () => {
		// @TODO onViewCompany functionality
	};

	return (
		<div className={styles.container}>
			<div className={styles.headerSection}>
				<h2>Relevant Companies and People in the Market</h2>
			</div>
			<hr />

			<div className={styles.listContainer}>
				<div className={styles.companiesContainer}>
					<span className={styles.logoContainer}>
						{dummyCompanies.map((company, index) => (
							<img
								key={index}
								alt={company.name}
								src={
									company.logo_url ??
									"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
								}
								className={styles.logo}
								style={{
									zIndex: index * 10 + 10,
									marginLeft: index * 20,
								}}
							/>
						))}
					</span>
					<div className={styles.viewButtonSection}>
						<span>55 Companies</span>
						<small onClick={onViewCompany}>
							View Companies{" "}
							<span>
								{" "}
								<ArrowRightIcon />
							</span>
						</small>
					</div>
				</div>
				<div className={styles.peopleContainer}>
					<span className={styles.logoContainer}>
						{dummyCompanies.map((company, index) => (
							<img
								key={index}
								alt={company.name}
								src={
									company.logo_url ??
									"https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
								}
								className={styles.logo}
								style={{
									zIndex: index * 10 + 10,
									marginLeft: index * 20,
								}}
							/>
						))}
					</span>
					<div className={styles.viewButtonSection}>
						<span>35 People</span>
						<small onClick={onViewPeople}>
							View People{" "}
							<span>
								{" "}
								<ArrowRightIcon />
							</span>
						</small>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RelevantInfo;
