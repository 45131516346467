import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion} from "mapx-components";
import {useCallback, useMemo} from "react";
import DealExperienceSearchFilter from "mapx-components/Filters/DealExperienceSearchFilter";
import {getCandidateCompanyEventsList} from "store/mapx/search/searchAsyncActions";
import {setCandidateCompanyEventsSearchQuery} from "store/mapx/search/searchActions";
import {
	TCandidateCompanyDateRangeItem,
	TCandidateCompanyEventSizeItem,
	TCandidateCompanyEventTypeItem,
	TDEDateRange,
} from "mapx-components/Filters/DealExperienceSearchFilter/types";
import {format} from "date-fns";
import {
	CandidateCompanyDateRangeOptionsSelector,
	candidateCompanyEventsSearchQuerySelector,
	CandidateCompanyEventsSelector,
} from "store/mapx/search/searchDealExperienceSelectors";
import {mRCandidateCompanyEventsSelector} from "store/mapx/market-report/marketReportFilterSelectors";
import {setMarketReportMultipleFilterValueInForm} from "store/mapx/market-report/marketReportActions";
import {
	setCompanyEventSizeFilterForMarketReportForm,
	setCompanyEventTypesFilterForMarketReportForm,
} from "store/mapx/market-report/marketReportFormAsyncActions";
import {
	filterDealExperienceDateRanges,
	formatDealExperienceDate,
} from "mapx-components/Filters/DealExperienceSearchFilter/utils";

function DealExperienceFilter() {
	const dispatch = useAppDispatch();

	const CandidateCompanyEventOptions = useAppSelector(CandidateCompanyEventsSelector);

	const CandidateCompanyDateRangeOptions = useAppSelector(
		CandidateCompanyDateRangeOptionsSelector,
	);

	const candidateCompanyEventsSearchQuery = useAppSelector(
		candidateCompanyEventsSearchQuerySelector,
	);

	const candidateCompanyEventsFilter = useAppSelector(mRCandidateCompanyEventsSelector);

	const handleResetClick = useCallback(
		(type: string) => {
			let resetOption;

			if (type === "size") {
				resetOption = {
					...candidateCompanyEventsFilter,
					size: [],
				};
			} else if (type === "types") {
				resetOption = {
					...candidateCompanyEventsFilter,
					types: [],
				};
			} else if (type === "dates") {
				resetOption = {
					...candidateCompanyEventsFilter,
					dates: [],
				};
			} else {
				resetOption = {
					types: [],
					size: [],
					dates: [],
				};
			}

			dispatch(
				setMarketReportMultipleFilterValueInForm({
					company_events: resetOption,
				}),
			);
		},
		[dispatch, candidateCompanyEventsFilter],
	);

	const handleEventTypeChange = useCallback(
		(eventItem: TCandidateCompanyEventTypeItem) => {
			dispatch(setCompanyEventTypesFilterForMarketReportForm({eventItem}));
		},
		[dispatch],
	);

	const handleOnEventSizeChange = useCallback(
		(eventSizeItem: TCandidateCompanyEventSizeItem) => {
			dispatch(setCompanyEventSizeFilterForMarketReportForm({eventSizeItem}));
		},
		[dispatch],
	);

	const handleOnDateChange = useCallback(
		(date: TCandidateCompanyDateRangeItem) => {
			if (date) {
				const min_value = date.min_value
					? format(new Date(date.min_value), "yyyy-MM-dd")
					: "";
				const max_value = date.max_value
					? format(new Date(date.max_value), "yyyy-MM-dd")
					: "";

				const updatedDates = filterDealExperienceDateRanges(
					candidateCompanyEventsFilter.dates || [],
					[
						{
							from_date: min_value,
							to_date: max_value,
						},
					],
				);

				dispatch(
					setMarketReportMultipleFilterValueInForm({
						company_events: {
							...candidateCompanyEventsFilter,
							dates: updatedDates,
						},
					}),
				);
			}
		},
		[dispatch, candidateCompanyEventsFilter],
	);

	function onBulkSelectDates() {
		const dates = CandidateCompanyDateRangeOptions;

		let data: TDEDateRange[] = [];

		if (Array.isArray(dates) && dates.length > 0) {
			data = formatDealExperienceDate(dates);
		}

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				company_events: {
					...candidateCompanyEventsFilter,
					dates: data,
				},
			}),
		);
	}

	function onBulkSelectTypes() {
		const types = CandidateCompanyEventOptions;

		let formattedEvents: string[] = [];
		if (Array.isArray(types) && types.length > 0) {
			formattedEvents = types.map((type) => {
				return type.name;
			});
		}

		dispatch(
			setMarketReportMultipleFilterValueInForm({
				company_events: {
					...candidateCompanyEventsFilter,
					types: formattedEvents,
				},
			}),
		);
	}

	const subtitle = useMemo(() => {
		return (
			candidateCompanyEventsFilter.size.length +
			candidateCompanyEventsFilter.types.length +
			candidateCompanyEventsFilter.dates.length
		);
	}, [
		candidateCompanyEventsFilter.size.length,
		candidateCompanyEventsFilter.types.length,
		candidateCompanyEventsFilter.dates.length,
	]);

	return (
		<Accordion title="Deal Experience" subtitle={subtitle}>
			<DealExperienceSearchFilter
				handleResetClick={handleResetClick}
				selectedDealExperienceItems={{
					types: candidateCompanyEventsFilter.types,
					sizes: candidateCompanyEventsFilter.size,
					dates: candidateCompanyEventsFilter.dates,
				}}
				handleOnChange={{
					onTypeChange: handleEventTypeChange,
					onSizeChange: handleOnEventSizeChange,
					onDateChange: handleOnDateChange,
				}}
				handleBulkSelect={{
					selectTypes: onBulkSelectTypes,
					selectDates: onBulkSelectDates,
				}}
				setCandidateCompanyTypesQueryOnState={setCandidateCompanyEventsSearchQuery}
				candidateCompanyTypesSearchQuery={candidateCompanyEventsSearchQuery}
				apiCall={getCandidateCompanyEventsList}
			/>
		</Accordion>
	);
}

export default DealExperienceFilter;
