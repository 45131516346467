import React from "react";
import styles from "./styles.module.scss";
import SeniorityFilter from "./SeniorityFilter";
import JobTitleFilter from "./JobTitleFilter";
import JobFunctionFilter from "./JobFunctionFilter";
import KeywordFilter from "./KeywordFilter";
import LocationFilter from "./LocationFilter";
import IndustryFilter from "./IndustryFilter";
import DealExperienceFilter from "./DealExperienceFilter";
import CompanyFilter from "./CompanyFilter";
import HeadcountFilter from "./HeadcountFilter";
import RevenueFilter from "./RevenueFilter";
import CompanyTypeFilter from "./CompanyTypeFilter";
import CompanyListIcon from "mapx-pages/MarketReports/Icons/CompanyListIcon";
import PeopleFiltersIcon from "mapx-pages/MarketReports/Icons/PeopleFilterIcon";
import CompanyFiltersIcon from "mapx-pages/MarketReports/Icons/CompanyFiltersIcon";
import {HelpIcon} from "assets/icons";
import {TUpdateMarketReportForm} from "api/marketReportApi/types";
import {useAppSelector} from "hooks";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";

const FilterSelection = () => {
	const marketReportForm: TUpdateMarketReportForm = useAppSelector(getMarketReportFormSelector);

	return (
		<div className={styles.filterSelectionWrapper}>
			<h2>Setup Filters</h2>

			{marketReportForm?.id === undefined && (
				<div className={styles.info}>
					<HelpIcon fill={"#0095FF"} /> To run a report please select either a job title
					or a seniority level and function/specialism. If no location is selected it will
					default to worldwide.
				</div>
			)}

			<div className={styles.filterColumns}>
				<div className={styles.filterColumn}>
					<h4>
						<PeopleFiltersIcon /> People Filters
					</h4>
					<div className={styles.filterWrapper}>
						<JobTitleFilter />
						<SeniorityFilter />
						<JobFunctionFilter />
						<KeywordFilter />
						<LocationFilter />
						<DealExperienceFilter />
					</div>
				</div>

				<div className={styles.filterColumn}>
					<div className={styles.companyFilters}>
						<h4>
							<CompanyFiltersIcon /> Company Filters
						</h4>
						<div className={styles.filterWrapper}>
							<IndustryFilter />
							<HeadcountFilter />
							<RevenueFilter />
							<CompanyTypeFilter />
						</div>
					</div>
				</div>

				<div className={styles.filterColumn}>
					<div className={styles.companyList}>
						<h4>
							<CompanyListIcon /> Company List
						</h4>
						<div className={styles.filterWrapper}>
							<CompanyFilter />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FilterSelection;
