import styles from "./Mobility.module.scss";
import MobilityCard from "./MobilityCard";
import {LazyLoadComponent} from "react-lazy-load-image-component";

const MarketReportMobility = () => {
	return (
		<LazyLoadComponent>
			<div className={styles.container}>
				<h2>Mobility</h2>
				<hr />
				<div className={styles.chartBox}>
					<MobilityCard
						title={"International Movements"}
						average={"2 Countries"}
						level={"Medium"}
						/*@TODO Indicates Indicator rotation.calculated value should be between 90 to -90*/
						value={0}
						description={"How internationally mobile this population is"}
						lowValueLabel={"0-1 Countries"}
						mediumValueLabel={"1-2 Countries"}
						highValueLabel={"2+ Countries"}
					/>
					<MobilityCard
						title={"Movement Frequency"}
						average={"1.6 Years"}
						level={"Low"}
						/*@TODO Indicates Indicator rotation.calculated value should be between 90 to -90*/
						value={-60}
						description={
							"How frequently these people have moved companies in the last 10 years excluding NED or Board Advisory roles."
						}
						lowValueLabel={">4 Years"}
						mediumValueLabel={"2-3 Years"}
						highValueLabel={"<2 Years"}
					/>
				</div>
			</div>
		</LazyLoadComponent>
	);
};

export default MarketReportMobility;
