import HttpClient from "api";
import {AxiosRequestConfig} from "axios";
import {TCreateMarketReportForm, TGetMarketReports} from "api/marketReportApi/types";

class MapXMarketReportsApi extends HttpClient {
	async getMarketReport(id: number | string) {
		return this.doGet(`/market-reports/${id}`);
	}

	async getMarketReports(
		{page, per_page = 20, created_by, name}: TGetMarketReports,
		config = {},
	) {
		const params: TGetMarketReports = {page, per_page, created_by, name};

		const queryParts: string[] = [];

		Object.keys(params).forEach((key: string) => {
			const value = params[key as keyof TGetMarketReports];
			if (value != null) {
				queryParts.push(
					`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`,
				);
			}
		});
		const filterQuery = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";

		return this.doGet(`/market-reports${filterQuery}`, config);
	}

	async searchMarketReports(
		{name = "", page = 1, per_page = 40}: TGetMarketReports,
		config?: AxiosRequestConfig,
	) {
		let filterQuery = `?page=${page}&per_page=${per_page}`;

		if (name !== "") {
			filterQuery = `${filterQuery}&name=${name}`;
		}

		return this.doGet(`/market-reports${filterQuery}`, config);
	}

	async createMarketReport(data: TCreateMarketReportForm) {
		return this.doPost("/market-reports", data);
	}

	async updateMarketReport(id: number, data: TCreateMarketReportForm) {
		return this.doPatch(`/market-reports/${id}`, data);
	}

	async deleteMarketReport(id: number) {
		return this.doDelete(`/market-reports/${id}`);
	}
}

const mapXMarketReportsApi = new MapXMarketReportsApi();

export default mapXMarketReportsApi;
