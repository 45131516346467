import styles from "./CoreRoleRequirements.module.scss";
import JobtitleIcon from "../Icons/JobtitleIcon";
import StarIcon from "../Icons/StartIcon";
import SpecialismIcon from "../Icons/SpecialismIcon";
import LocationIcon from "../Icons/LocationIcon";
import RequirementsCard from "./Card/RequirementsCard";
import {TUpdateMarketReportForm} from "api/marketReportApi/types";
import {useAppSelector} from "hooks";
import {getMarketReportFormSelector} from "store/mapx/market-report/marketReportSelector";
import {useMemo, useState} from "react";
import {seniorityOptionSelector} from "store/mapx/search/searchSelectors";
import {TSeniorityItem} from "mapx-components/Filters/SenioritySearchFilter/types";
import {
	mrCurrentJobFunctionTagsSelector,
	mRSelectedCurrentCountriesSelector,
} from "store/mapx/market-report/marketReportFilterSelectors";
import {ExpandIcon, HelpIcon, MinimizeIcon} from "assets/icons";

const CoreRoleRequirements = () => {
	const marketReportForm: TUpdateMarketReportForm = useAppSelector(getMarketReportFormSelector);

	const seniorityOptions = useAppSelector(seniorityOptionSelector);

	const currentSpecialismTags = useAppSelector(mrCurrentJobFunctionTagsSelector);

	const currentLocations = useAppSelector(mRSelectedCurrentCountriesSelector);

	const [expandedAll, setExpandedAll] = useState(false);

	const seniorityList = useMemo(() => {
		const seniorityMap = new Map<string, string>(
			seniorityOptions.map((item: TSeniorityItem) => [item.id, item.name]),
		);

		return (
			marketReportForm.filters?.current_seniority
				?.map((id) => seniorityMap.get(id))
				.filter((name): name is string => name !== undefined) || []
		);
	}, [marketReportForm.filters?.current_seniority, seniorityOptions]);

	return (
		<div className={styles.wrapper}>
			<h2>
				Core Role Requirements{" "}
				{expandedAll ? (
					<MinimizeIcon
						style={{cursor: "pointer"}}
						onClick={() => setExpandedAll(!expandedAll)}
					/>
				) : (
					<ExpandIcon
						style={{cursor: "pointer"}}
						onClick={() => setExpandedAll(!expandedAll)}
					/>
				)}
			</h2>

			<div className={styles.container}>
				{marketReportForm?.id === undefined && (
					<div className={styles.info}>
						<HelpIcon fill={"#0095FF"} /> Core role requirements are used to generate an
						estimated market size based on current job title/role in a location.
					</div>
				)}

				<div className={styles.cards}>
					<RequirementsCard
						title={"Job title"}
						count={marketReportForm.filters?.current_job_titles_or?.length || 0}
						subtitle={"Add a job title..."}
						values={marketReportForm.filters?.current_job_titles_or || []}
						icon={<JobtitleIcon />}
						expanded={expandedAll}
					/>
					<RequirementsCard
						title={"Seniority"}
						count={seniorityList?.length || 0}
						subtitle={"Select seniority..."}
						values={seniorityList || []}
						icon={<StarIcon />}
						expanded={expandedAll}
					/>
					<RequirementsCard
						title={"Function & Specialisms"}
						count={currentSpecialismTags?.length || 0}
						subtitle={"Select specialism..."}
						values={
							currentSpecialismTags?.map(
								(item) => `${item.jobFunctionName}: ${item.name}`,
							) || []
						}
						icon={<SpecialismIcon />}
						expanded={expandedAll}
					/>
					<RequirementsCard
						title={"Location"}
						count={currentLocations?.length}
						subtitle={
							marketReportForm.id !== undefined ? "Worldwide" : "Select location..."
						}
						values={currentLocations.map((item) => item.name)}
						icon={<LocationIcon />}
						expanded={expandedAll}
					/>
				</div>
			</div>
		</div>
	);
};

export default CoreRoleRequirements;
