import React, {useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import {
	ArrowDown,
	RecommendAltIcon,
	RefreshAltIcon,
	RejectMinusIcon,
	SummaryIcon,
} from "assets/icons";
import {Dropdown} from "components";
import TelephoneIcon from "mapx-components/Cards/CandidateContactDetails/Icons/TelephoneIcon";
import {
	BulkActionOption,
	TBulkStatusChangeParams,
} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	bulkStatusChangeInProgressSelector,
	fetchingProgressStatusesSelector,
	projectProgressStatusesSelector,
} from "store/mapx/project/projectSelectors";
import {STProjectProgressStatus} from "api/projectApi/types";
import Loader from "components/Plugins/Loader";
import customConfirmAlert from "helpers/customConfirmAlert";
import {generateCandidatesSummary} from "store/mapx/project/generateCandidateSummaryAsyncActions";
import {generateCandidatesContactDetails} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import {getAllGenerateSummaryCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateSummarySelectors";
import {getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateContactDetailsSelectors";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {selectedCandidateIdsForAssessmentSelector} from "store/mapx/project/candidateScoringSelectors";
import {isNumber, noop} from "lodash";
import BulkActionsIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/bulkActionsIcon";
import {
	changeProjectCandidateStatus,
	removeSelectedCandidatesFromProject,
} from "store/mapx/project/projectAsyncActions";

import {bulkRefreshCandidateProfiles} from "store/mapx/refresh-candidate/refreshCandidateAsyncActions";
import {bulkRefreshInProgressSelector} from "store/mapx/refresh-candidate/refreshCandidateSelectors";

const BulkActions = () => {
	const dispatch = useAppDispatch();

	const selectedCandidatesIdsForActions = useAppSelector(
		selectedCandidateIdsForAssessmentSelector,
	);

	const projectProgressStatuses: STProjectProgressStatus[] = useAppSelector(
		projectProgressStatusesSelector,
	);

	const fetchingProgressStatuses = useAppSelector(fetchingProgressStatusesSelector);

	const bulkStatusChangeInProgress = useAppSelector(bulkStatusChangeInProgressSelector);

	const bulkRefreshInProgress = useAppSelector(bulkRefreshInProgressSelector);

	const targetListID = useAppSelector(targetListIDSelector);

	const [deletingCandidates, setDeletingCandidates] = useState(false);

	const [creatingSummaryRequest, setCreatingSummaryRequest] = useState(false);

	const [creatingContactDetailsRequest, setCreatingContactDetailsRequest] = useState(false);

	const isSummaryForInProgressForCurrentProject = useAppSelector<number[]>((state) =>
		getAllGenerateSummaryCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	const isContactDetailsForInProgressForCurrentProject = useAppSelector<number[]>((state) =>
		getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	const generatingSummaryForSelectedIds = useMemo(
		() => creatingSummaryRequest || isSummaryForInProgressForCurrentProject?.length > 0,
		[creatingSummaryRequest, isSummaryForInProgressForCurrentProject?.length],
	);

	const generatingContactDetailsForSelectedIds = useMemo(
		() =>
			creatingContactDetailsRequest ||
			isContactDetailsForInProgressForCurrentProject?.length > 0,
		[creatingContactDetailsRequest, isContactDetailsForInProgressForCurrentProject?.length],
	);

	const handleGenerateAllSummariesButtonClick = useCallback(async () => {
		let message;

		if (generatingSummaryForSelectedIds) {
			message =
				"A summary generation is in progress. Try again after the current action is completed.";
		} else if (selectedCandidatesIdsForActions?.length > 1000) {
			message = "A maximum limit of candidate profiles limit for summary generation is 1000.";
		}

		if (message) {
			customConfirmAlert({
				yesButtonLabel: "Close",
				onlyYesButton: true,
				title: message,
				message: `The request can not be processed!`,
				alertType: "warning",
				handlePressYes: noop,
			});
		} else {
			customConfirmAlert({
				yesButtonLabel: "Generate",
				noButtonLabel: "Back",
				title: `This action will generate ${selectedCandidatesIdsForActions?.length} candidate summaries.`,
				message: `Would you like to continue?`,
				alertType: "warning",
				handlePressYes: async () => {
					setCreatingSummaryRequest(true);

					await dispatch(generateCandidatesSummary(selectedCandidatesIdsForActions));

					setCreatingSummaryRequest(false);
				},
			});
		}
	}, [dispatch, generatingSummaryForSelectedIds, selectedCandidatesIdsForActions]);

	const handleGenerateAllContactDetailsButtonClick = useCallback(async () => {
		let message;

		if (generatingContactDetailsForSelectedIds) {
			message =
				"A contact details generation is in progress. Try again after the current action is completed.";
		} else if (selectedCandidatesIdsForActions?.length > 1000) {
			message =
				"A maximum limit of candidate profiles limit for contact details generation is 1000.";
		}

		if (message) {
			customConfirmAlert({
				yesButtonLabel: "Close",
				onlyYesButton: true,
				title: message,
				message: `The request can not be processed!`,
				alertType: "warning",
				handlePressYes: noop,
			});
		} else {
			customConfirmAlert({
				yesButtonLabel: "Generate",
				noButtonLabel: "Back",
				title: `This action will generate ${selectedCandidatesIdsForActions?.length} candidate contact details.`,
				message: `Would you like to continue?`,
				alertType: "warning",
				handlePressYes: async () => {
					setCreatingContactDetailsRequest(true);

					await dispatch(
						generateCandidatesContactDetails(selectedCandidatesIdsForActions),
					);

					setCreatingContactDetailsRequest(false);
				},
			});
		}
	}, [dispatch, generatingContactDetailsForSelectedIds, selectedCandidatesIdsForActions]);

	const dropdownOptions = useMemo(() => {
		const BULK_ACTIONS_OPTIONS: BulkActionOption[] = [
			{
				value: "set_recommended",
				name: "Recommend",
				icon: <RecommendAltIcon />,
				loading: bulkStatusChangeInProgress,
			},
			{
				value: "set_status",
				name: "Set Status as",
				children: [
					{
						value: null,
						name: "Remove Status",
						icon: <RejectMinusIcon color={"#4E5555"} />,
					},
				],
				hasBreakLine: true,
				loading: bulkStatusChangeInProgress,
			},
			{value: "generate_summary", name: "Generate Summary", icon: <SummaryIcon />},
			{
				value: "generate_contact_details",
				name: "Generate Contact Details",
				icon: <TelephoneIcon />,
			},
			{
				value: "refresh_profiles",
				name: "Refresh Profiles",
				icon: <RefreshAltIcon />,
				hasBreakLine: true,
			},
			{
				value: "remove_from_project",
				name: "Remove",
				icon: <RejectMinusIcon />,
				loading: deletingCandidates,
			},
		];

		return BULK_ACTIONS_OPTIONS.map((option) => {
			if (
				option.value === "set_status" &&
				option.children &&
				projectProgressStatuses?.length > 0
			) {
				return {
					...option,
					children: [
						...projectProgressStatuses.map((status) => ({
							value: status.id,
							name: status.name,
						})),
						...option.children,
					],
				};
			}

			return option;
		});
	}, [bulkStatusChangeInProgress, projectProgressStatuses, deletingCandidates]);

	const isBulkActionsIsInProgress = useMemo(() => {
		return (
			bulkStatusChangeInProgress ||
			bulkRefreshInProgress ||
			generatingSummaryForSelectedIds ||
			generatingContactDetailsForSelectedIds ||
			deletingCandidates
		);
	}, [
		deletingCandidates,
		bulkRefreshInProgress,
		bulkStatusChangeInProgress,
		generatingSummaryForSelectedIds,
		generatingContactDetailsForSelectedIds,
	]);

	const handleCandidatesStatusClick = useCallback(
		async ({isRecommended, statusId}: TBulkStatusChangeParams) => {
			if (selectedCandidatesIdsForActions?.length === 0) {
				customConfirmAlert({
					yesButtonLabel: "Close",
					onlyYesButton: true,
					title: "Please select candidates to change status.",
					message: `The request can not be processed!`,
					alertType: "warning",
					handlePressYes: noop,
				});
			} else {
				if (isRecommended !== undefined) {
					await dispatch(changeProjectCandidateStatus({isRecommended}));
				} else if (statusId !== undefined) {
					await dispatch(changeProjectCandidateStatus({statusId}));
				}
			}
		},
		[dispatch, selectedCandidatesIdsForActions?.length],
	);

	const handleRefreshCandidatesClick = useCallback(async () => {
		if (selectedCandidatesIdsForActions?.length === 0) {
			customConfirmAlert({
				yesButtonLabel: "Close",
				onlyYesButton: true,
				title: "Please select candidates to start refreshing the profiles.",
				message: `The request can not be processed!`,
				alertType: "warning",
				handlePressYes: noop,
			});
		} else {
			customConfirmAlert({
				yesButtonLabel: "Proceed",
				noButtonLabel: "Back",
				title: `You are about to refresh ${selectedCandidatesIdsForActions?.length} profiles in this project`,
				message: `Do you want to proceed?`,
				alertType: "warning",
				handlePressYes: () => dispatch(bulkRefreshCandidateProfiles()),
			});
		}
	}, [selectedCandidatesIdsForActions?.length, dispatch]);

	const onSelect = useCallback(
		async (option: BulkActionOption) => {
			if (option.value === "generate_summary") {
				await handleGenerateAllSummariesButtonClick();
			} else if (option.value === "generate_contact_details") {
				await handleGenerateAllContactDetailsButtonClick();
			} else if (option.value === "set_recommended") {
				await handleCandidatesStatusClick({isRecommended: true});
			} else if (isNumber(option.value)) {
				await handleCandidatesStatusClick({statusId: option.value});
			} else if (option.name === "Remove Status") {
				await handleCandidatesStatusClick({statusId: null});
			} else if (option.value === "remove_from_project") {
				setDeletingCandidates(true);
				await dispatch(removeSelectedCandidatesFromProject());
				setDeletingCandidates(false);
			} else if (option.value === "refresh_profiles") {
				await handleRefreshCandidatesClick();
			}
		},
		[
			handleGenerateAllContactDetailsButtonClick,
			handleGenerateAllSummariesButtonClick,
			handleCandidatesStatusClick,
			handleRefreshCandidatesClick,
			dispatch,
		],
	);

	const InlineLoaderComponent = <Loader height={16} width={16} color={"#4E5555"} type={"Oval"} />;

	return (
		<div className={styles.container}>
			{fetchingProgressStatuses && InlineLoaderComponent}

			{!fetchingProgressStatuses && (
				<Dropdown
					key={"Default"}
					dropdownButtonClass={styles.dropdownButton}
					dropdownMenuClass={styles.dropdownMenu}
					onSelect={onSelect}
					dropdownAlign={"left"}
					options={dropdownOptions}
					isLoading={(option) => !!option.loading}
					getOptionLabel={(option) => option.name}
					isOptionValueNull={(option) => option.value === null}
					getOptionIcon={(option) => option.icon}
					hasSeparator={(option) => !!option.hasBreakLine}
					/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
					// @ts-ignore
					getChildrenItems={(option) => (option.children ? option.children : [])}
					dropdownButtonWidth={256}
					placeholder={
						<div className={styles.bulkActionsButton}>
							<div className={styles.title}>
								<BulkActionsIcon /> Bulk Actions{" "}
								{isBulkActionsIsInProgress && InlineLoaderComponent}
							</div>
							<div>
								<ArrowDown />
							</div>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default BulkActions;
