import styles from "./Tenure.module.scss";
import {TTenureCardProps} from "../types";
import {LazyLoadComponent} from "react-lazy-load-image-component";

const TenureCard = ({title, average, description, data}: TTenureCardProps) => {
	const maxValue = Math.max(...data.map((item) => item.value));
	const maxWidth = 280;

	return (
		<LazyLoadComponent>
			<div className={styles.tenureCOntainer}>
				<div className={styles.titleSection}>
					<p>{title}</p>
					<span className={styles.average}>
						Average:<span> {average}</span>
					</span>
				</div>
				<hr />
				<p className={styles.description}>{description}</p>
				{/* Chart */}
				<div className={styles.chartContainer}>
					{data.map((item, index) => {
						const barWidth = (item.value / maxValue) * maxWidth;

						return (
							<div key={index} className={styles.chartRow}>
								<span className={styles.chartLabel}>{item.label}</span>

								<div
									className={styles.chartBar}
									style={{
										width: `${barWidth}px`,
									}}
								>
									{item.value > 0 && (
										<span className={styles.chartValue}>{item.value}</span>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</LazyLoadComponent>
	);
};

export default TenureCard;
