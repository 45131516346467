import {TRootState} from "types";

export const loadingMarketReportSelector = (state: TRootState) =>
	state.marketReport.loadingMarketReport;
export const creatingMarketReportSelector = (state: TRootState) =>
	state.marketReport.creatingMarketReport;
export const updatingMarketReportSelector = (state: TRootState) =>
	state.marketReport.updatingMarketReport;
export const deletingMarketReportSelector = (state: TRootState) =>
	state.marketReport.deletingMarketReport;
export const duplicatingMarketReportSelector = (state: TRootState) =>
	state.marketReport.duplicatingMarketReport;
export const displayMarketReportModalSelector = (state: TRootState) =>
	state.marketReport.displayMarketReportModal;
export const getMarketReportsSelector = (state: TRootState) => state.marketReport.results;
export const getMarketReportsPaginationSelector = (state: TRootState) =>
	state.marketReport.pagination;
export const getMarketReportCreatedBySelector = (state: TRootState) => state.marketReport.createdBy;
export const getMarketReportFormSelector = (state: TRootState) =>
	state.marketReport.marketReportForm;
export const getMRSelectedCompaniesSelector = (state: TRootState) =>
	state.marketReport.selectedCompanies;
