import {useAppDispatch, useAppSelector} from "hooks";
import React, {useEffect} from "react";
import styles from "./marketReports.module.scss";
import {Button} from "components";
import {CreatePlusIcon} from "assets/icons";
import homeStyles from "../Home/HomeStyles.module.scss";
import useSearchDebounce from "hooks/useSearchDebounce";
import MarketReportCard from "mapx-components/Cards/MarketReportCard";
import {MainWrapper} from "mapx-components";
import MarketReportSkeletonLoader from "./Loader";
import MarketReportModal from "mapx-components/Modals/MarketReportModal";
import {
	displayMarketReportModal,
	resetMarketReportForm,
} from "store/mapx/market-report/marketReportActions";
import {
	getMarketReportsPaginationSelector,
	getMarketReportsSelector,
	loadingMarketReportSelector,
} from "store/mapx/market-report/marketReportSelector";
import {ATPagination} from "api/types";
import {IMarketReport} from "api/marketReportApi/types";
import {cancelRequest} from "api/cancelTokens";
import {GET_MARKET_REPORTS_TOKEN} from "api/requestCancelTokenStrings";
import {getMarketReports} from "store/mapx/market-report/marketReportAsyncActions";

const MarketReports = () => {
	const dispatch = useAppDispatch();

	const marketReportsFetchInProgress = useAppSelector(loadingMarketReportSelector);

	const marketReports = useAppSelector(getMarketReportsSelector);

	const pagination: Nullable<ATPagination> = useAppSelector(getMarketReportsPaginationSelector);

	const [searchTerm] = useSearchDebounce(800);

	// const [currentSearch, setCurrentSearch] = useState("");

	// const createdBy = useAppSelector(getMarketReportCreatedBySelector);

	// const [selected, setSelected] = useState<TMultiSelectOptions[]>(
	//     createdBy.length === 0 ? options : options.filter((item) => createdBy.includes(item.value)),
	// );

	const LoaderComponent = <MarketReportSkeletonLoader count={10} />;

	const handleCreateMarketReportClick = () => {
		dispatch(displayMarketReportModal(true));
		dispatch(resetMarketReportForm());
	};

	// const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
	//     setSearchTerm(e.target.value);
	//     setCurrentSearch(e.target.value);
	// };

	useEffect(() => {
		dispatch(getMarketReports());

		return () => {
			cancelRequest(GET_MARKET_REPORTS_TOKEN);
		};
	}, [dispatch]);

	return (
		<MainWrapper>
			<div className={styles.container}>
				<div className={styles.headerSection}>
					<div className={styles.title}> Market Reports</div>

					<div className={styles.headerSection}>
						{/*<MarketMapSearch*/}
						{/*    onChange={handleSearchInput}*/}
						{/*    loading={marketReportsFetchInProgress}*/}
						{/*    value={currentSearch}*/}
						{/*/>*/}

						{/*<MultiSelectWithAction*/}
						{/*    className={styles.multiSelect}*/}
						{/*    isSelected={selected.length > 0}*/}
						{/*    options={options}*/}
						{/*    placeholder="Created by"*/}
						{/*    selected={selected}*/}
						{/*    setSelected={setSelected}*/}
						{/*/>*/}

						<Button
							data-testid="addNewMarketReportButtonOnMarketReportsList"
							LeftIcon={CreatePlusIcon}
							onClick={handleCreateMarketReportClick}
							className={homeStyles.content__bottom__info__buttons_create}
							leftIconClasses={homeStyles.content__bottom__info__buttons_create_icon}
						>
							Create a Market Report
						</Button>
					</div>
				</div>

				{marketReportsFetchInProgress && LoaderComponent}

				{!marketReportsFetchInProgress &&
					marketReports?.length > 0 &&
					marketReports.map((item: IMarketReport, index: number) => (
						<MarketReportCard
							key={`${index}${item.id}${item.name}`}
							id={item.id}
							name={item.name}
							market_size={item.total_market_size}
							profile_matched={item.candidate_matches}
							created_date={item.created_at}
							updated_date={item.last_modified_at}
							filters={item.filters}
						/>
					))}

				{/*@TODO pagination here*/}

				{!pagination?.count && searchTerm !== "" && (
					<div className={styles.noQueryMatch}>
						<h4>No market report was found with your search query</h4>
						<p>Try with something different</p>
					</div>
				)}

				{!pagination?.count && searchTerm === "" && (
					<div className={styles.noQueryMatch} onClick={handleCreateMarketReportClick}>
						<h4>{"You don't have any market reports yet!"}</h4>
						<p>
							Click on the Create a Market Report button to get started with a Market
							Report
						</p>
					</div>
				)}

				<MarketReportModal />
			</div>
		</MainWrapper>
	);
};

export default MarketReports;
