import styles from "./CriteriaBreakDown.module.scss";
import classNames from "classnames";
import {EyeShowIcon, EyeHideIcon, PencilIcon} from "assets/icons";
import {Button} from "../../../components";
import React from "react";

const CriteriaBreakDown = () => {
	return (
		<div className={styles.container}>
			<div className={styles.headerSection}>
				<h2>Criteria Breakdown</h2>
				<Button name={"Edit Filters"} type={"button"} className={styles.editFilterButton}>
					<PencilIcon color={"rgba(12, 88, 80, 1)"} /> Edit Filters
				</Button>
			</div>
			<hr />
			<div className={styles.criteriaBreakdown}>
				{/* Funnel Section */}
				<div className={styles.funnel}>
					<div className={styles.funnelContainer}>
						<div className={classNames(styles.funnel1SquareSection)}></div>
						<div className={classNames(styles.funnelSegment, styles.funnel1)}>
							<span
								className={classNames(
									styles.segmentLabel,
									styles.adjustTextToTopMost,
								)}
							>
								Estimated Total Market Size
							</span>
						</div>
					</div>

					<div className={styles.funnelContainer}>
						<div className={classNames(styles.funnelSegment, styles.funnel2)}>
							<span
								className={classNames(
									styles.segmentLabel,
									styles.adjustTextToTopMost,
								)}
							>
								In a Relevant Company
							</span>
						</div>
					</div>

					<div className={styles.funnelContainer}>
						<div className={classNames(styles.funnelSegment, styles.funnel3)}>
							<p className={styles.extendedSection}></p>
							<span
								className={classNames(styles.segmentLabel, styles.adjustTextToTop)}
							>
								Industry
							</span>
						</div>
					</div>

					<div className={styles.funnelContainer}>
						<div
							className={classNames(
								styles.funnelSegment,
								styles.funnelSquare,
								styles.industryColor,
							)}
						>
							<span className={classNames(styles.segmentLabel)}>Industry</span>
						</div>
					</div>

					<div className={styles.funnelContainer}>
						<div
							className={classNames(
								styles.funnelSegment,
								styles.funnelSquare,
								styles.headCountColor,
							)}
						>
							<span className={classNames(styles.segmentLabel)}>
								Company Headcount
							</span>
						</div>
					</div>

					<div className={styles.funnelContainer}>
						<div
							className={classNames(
								styles.funnelSegment,
								styles.funnelSquare,
								styles.keywordsColor,
							)}
						>
							<span className={classNames(styles.segmentLabel)}>Keywords</span>
						</div>
					</div>

					<div className={styles.funnelContainer}>
						<div
							className={classNames(
								styles.funnelSegment,
								styles.funnelSquare,
								styles.languagesColor,
							)}
						>
							<span className={classNames(styles.segmentLabel)}>Languages</span>
						</div>
					</div>

					<div className={styles.funnelContainer}>
						<div className={classNames(styles.funnelSegment, styles.funnelEnd)}>
							<span
								className={classNames(styles.segmentLabel, styles.adjustTextToTop)}
							>
								Deal Experience
							</span>
						</div>
					</div>
				</div>

				{/* Horizontal Bars Section */}
				<div className={styles.horizontalBars}>
					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={styles.barInfo}>
								<span className={styles.barCount}>10,125</span>
								<span className={styles.barPercentage}>100%</span>
							</div>
							<div>
								<span className={styles.barLabel}>
									<span>Estimated Total Market Size</span>
									<span className={styles.lightText}>Core Role Requirements</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.marketSizeBar),
											styles.marketSizeBar,
										)}
										style={{width: "100%"}}
									></div>
								</div>
							</div>
						</div>
					</div>

					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={styles.barInfo}>
								<span className={styles.barCount}>4,860</span>
								<span className={styles.barPercentage}>48%</span>
							</div>
							<div>
								<span className={styles.barLabel}>
									<span>55 Companies</span>
									<span className={styles.lightText}>In a relevant company</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.companyBar),
											styles.marketSizeBar,
										)}
										style={{width: "48%"}}
									></div>
								</div>
							</div>
							<div className={styles.actionList}>
								<EyeShowIcon />
								<PencilIcon color={"rgba(131, 125, 124, 1)"} />
							</div>
						</div>
					</div>

					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={styles.barInfo}>
								<span className={styles.barCount}>3,645</span>
								<span className={styles.barPercentage}>36%</span>
							</div>
							<div>
								<span className={styles.barLabel}>
									<span>Software Development</span>
									<span className={styles.lightText}>Currently, Industry</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.industryColor),
										)}
										style={{width: "36%"}}
									></div>
								</div>
							</div>
							<div className={styles.actionList}>
								<EyeShowIcon />
								<PencilIcon color={"rgba(131, 125, 124, 1)"} />
							</div>
						</div>
					</div>

					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={styles.barInfo}>
								<span className={styles.barCount}>2,227</span>
								<span className={styles.barPercentage}>22%</span>
							</div>
							<div>
								<span className={styles.barLabel}>
									<span>Information and Internet</span>
									<span className={styles.lightText}>Previously, Industry</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.industryColor),
										)}
										style={{width: "22%"}}
									></div>
								</div>
							</div>
							<div className={styles.actionList}>
								<EyeShowIcon />
								<PencilIcon color={"rgba(131, 125, 124, 1)"} />
							</div>
						</div>
					</div>

					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={styles.barInfo}>
								<span className={styles.barCount}>960</span>
								<span className={styles.barPercentage}>22%</span>
							</div>
							<div>
								<span className={styles.barLabel}>
									<span>
										10,000 to 25,000{" "}
										<span className={styles.lightText}>OR</span> 25,000 to
										50,000
									</span>
									<span className={styles.lightText}>Company Headcount</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.headCountColor),
										)}
										style={{width: "22%"}}
									></div>
								</div>
							</div>
							<div className={styles.actionList}>
								<EyeShowIcon />
								<PencilIcon color={"rgba(131, 125, 124, 1)"} />
							</div>
						</div>
					</div>

					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={classNames(classNames(styles.barInfo, styles.faded))}>
								<span className={styles.barCount}>2,227</span>
								<span className={styles.barPercentage}>22%</span>
							</div>
							<div className={styles.faded}>
								<span className={styles.barLabel}>
									<span>
										Chief <span className={styles.lightText}>AND</span> Officer
									</span>
									<span className={styles.lightText}>Currently, Keywords</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.keywordsColor),
										)}
										style={{width: "22%"}}
									></div>
								</div>
							</div>
							<div className={styles.actionList}>
								<EyeHideIcon />
								<PencilIcon color={"rgba(131, 125, 124, 1)"} />
							</div>
						</div>
					</div>

					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={styles.barInfo}>
								<span className={styles.barCount}>1,417</span>
								<span className={styles.barPercentage}>13%</span>
							</div>
							<div>
								<span className={styles.barLabel}>
									<span>
										English <span className={styles.lightText}>AND</span> German
									</span>
									<span className={styles.lightText}>Languages</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.languagesColor),
										)}
										style={{width: "13%"}}
									></div>
								</div>
							</div>
							<div className={styles.actionList}>
								<EyeShowIcon />
								<PencilIcon color={"rgba(131, 125, 124, 1)"} />
							</div>
						</div>
					</div>

					<div className={styles.barContainer}>
						<div className={styles.barInfoContainer}>
							<div className={styles.barInfo}>
								<span className={styles.barCount}>540</span>
								<span className={styles.barPercentage}>5%</span>
							</div>
							<div>
								<span className={styles.barLabel}>
									<span>
										Acquisition <span className={styles.lightText}>AND</span>{" "}
										$150m to $250m
									</span>
									<span className={styles.lightText}>Deal Experience</span>
								</span>
								<div className={styles.bar}>
									<div
										className={classNames(
											classNames(styles.barFill, styles.DEColor),
										)}
										style={{width: "5%"}}
									></div>
								</div>
							</div>
							<div className={styles.actionList}>
								<EyeShowIcon />
								<PencilIcon color={"rgba(131, 125, 124, 1)"} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<h3>
				Estimated Profile Matches: <span>540</span>
			</h3>
		</div>
	);
};

export default CriteriaBreakDown;
