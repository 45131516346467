import React, {useRef, useState} from "react";
import {BiDotsVerticalRounded} from "react-icons/bi";
import styles from "./MarketReportCard.module.scss";
import colors from "styles/themes.module.scss";
import {Button, Loader} from "components";
import useOnClickOutside from "hooks/useOnClickOutside";
import customConfirmAlert from "helpers/customConfirmAlert";
import {FaCaretRight} from "react-icons/fa";
import {TMarketReportItem} from "mapx-pages/MarketReports/types";
import {convertTimeStampToDate, formatNumber} from "helpers/string";
import {useAppDispatch} from "hooks";
import {setMarketReportFormData} from "store/mapx/market-report/marketReportActions";
import {useHistory} from "react-router-dom";

export default function MarketReportCard(marketReport: TMarketReportItem) {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const [activeMenu, setActiveMenu] = useState(false);

	const [loading] = useState(false);

	const handleItemClick = () => {
		dispatch(
			setMarketReportFormData({
				id: marketReport.id,
				name: marketReport.name,
				filters: marketReport.filters,
			}),
		);

		history.push(`/market-reports/${marketReport.id}`);
	};

	const handleEditClick = () => {
		setActiveMenu(false);

		dispatch(
			setMarketReportFormData({
				id: marketReport.id,
				name: marketReport.name,
				filters: marketReport.filters,
			}),
		);

		history.push(`/market-reports/edit/${marketReport.id}`);
	};

	const handleDeleteClick = () => {
		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "Market Reports Deletion Warning!",
			message: `Are you sure about deleting the market report?`,
			handlePressYes: () => {
				setActiveMenu(false);
				console.log("id to delete: ", marketReport.id);
				// @TODO Delete actions
			},
			afterClose: () => {
				setActiveMenu(false);
			},
		});
	};

	const toggleMenu = () => {
		setActiveMenu(!activeMenu);
	};

	const optionsRef = useRef(null);

	useOnClickOutside(optionsRef, () => setActiveMenu(false));

	return (
		<span className={styles.content}>
			<div className={styles.textContainer}>
				<div className={styles.listInfo}>
					<h2 onClick={handleItemClick}>
						{marketReport?.name ?? "Unnamed Market Report"}
					</h2>
					<div className={styles.statsContainer}>
						<section>
							<div className={styles.statInfo}>
								<span>
									Total Market Size:{" "}
									<span className={styles.count}>
										{formatNumber(marketReport.market_size)}
									</span>
								</span>
								<span>
									Profile Matches:{" "}
									<span className={styles.count}>
										{formatNumber(marketReport.profile_matched)}
									</span>
								</span>
							</div>
							<div className={styles.dates}>
								<span>
									Created in:{" "}
									<span className={styles.date}>
										{convertTimeStampToDate(marketReport.created_date)}
									</span>
								</span>
								<span>
									Last Updated:{" "}
									<span className={styles.date}>
										{convertTimeStampToDate(marketReport.updated_date)}
									</span>
								</span>
							</div>
						</section>

						<div className={styles.marketMapButtons}>
							<Button className={styles.openReportButton} onClick={handleItemClick}>
								<span>View Report</span>{" "}
								<FaCaretRight color={"#0C5850"} size="15px" />
							</Button>
						</div>
					</div>
				</div>

				<div ref={optionsRef}>
					<span className={activeMenu ? styles.active : styles.hidden}>
						<div className={styles.marketMapMenu}>
							<div className={styles.menuItem} onClick={handleEditClick}>
								Edit Report
							</div>

							<div className={styles.menuItem} onClick={handleDeleteClick}>
								Remove Report
							</div>
						</div>
					</span>

					{loading ? (
						<Loader
							height={16}
							width={16}
							type="ThreeDots"
							color={colors.loaderDotColor}
						/>
					) : (
						<BiDotsVerticalRounded
							size={24}
							className={styles.menuDots}
							onClick={toggleMenu}
						/>
					)}
				</div>
			</div>
		</span>
	);
}
